import { Observable, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenStorage } from '@core/auth/token-storage.service';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { select, Store } from '@ngrx/store';
import { UserState } from '@core/states/user.state';
import * as UserActions from '@core/actions/user.actions';
import { UtilsService } from '@core/services/utils.service';
import { getPublicSettings } from '@store/common';
import { IPublicSettings } from '@core/interfaces/store-data';

// declare var aptrinsic: any;

@Injectable()
export class UserProfileService {
  public user_obj: UserState;
  public full_name: string;

  constructor(
    private http: HttpClient,
    private httpUtilsService: HttpUtilsService,
    private utilService: UtilsService,
    private tokenStorage: TokenStorage,
    private store: Store<any>,
  ) {
  }

  public getUsername(): Observable<string> {
    return this.tokenStorage.getUsername();
  }

  public getUserProfile(credential): Observable<any> {
    return this.httpUtilsService.post('userAccount/getUserProfile', this.httpUtilsService.formatToUrlencoded(credential),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      })
      .pipe(
        map((result: any) => {
          result = JSON.parse(result.res);
          return result.length === 0 ? result : result[0];
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  public getUserProfileObj(userName: string = ''): Observable<any> {
    const model: any = {
      user: userName
    };
    // this.getUsername()
    //   .pipe(
    //     filter(value => !!value)
    //   )
    //   .subscribe(val => {
    //     model.user = val;
    //   });

    return this.getUserProfile(model)
      .pipe(
        tap((response: any) => {
          if (response.USER_FIRST_NAME && response.USER_LAST_NAME) {
            this.full_name = `${response.USER_FIRST_NAME} ${response.USER_LAST_NAME}`;
          } else {
            this.full_name = response.USER_NAME.split('@')[0];
          }

          this.user_obj = {
            id: response.USER_ID,
            email: response.USER_NAME,
            full_name: this.full_name,
            image: response.USER_PICTURE,
            company: response.COMPANY,
            first_name: response.USER_FIRST_NAME,
            last_name: response.USER_LAST_NAME,
            about_me: response.ABOUT_ME,
            job_title: response.JOB_TITLE,
            user_active: response.USER_ACTIVE,
            user_auth_level: response.USER_AUTH_LEVEL,
            user_permissions: this.utilService.toLowerCaseAllArrayValues(response.USER_PERMISSIONS.split(';'))
          };

          let publicSettings: IPublicSettings;
          this.store
            .pipe(
              select(getPublicSettings),
              take(1),
            )
            .subscribe(value => publicSettings = value);

          // try {
          //   aptrinsic('identify', {
          //     id: this.full_name,
          //     email: response.USER_NAME,
          //     title: response.JOB_TITLE,
          //     role: response.USER_AUTH_LEVEL,
          //   }, {
          //     id: publicSettings.companyName,
          //     name: publicSettings.companyName,
          //     customRegionName: publicSettings.region,
          //   });
          // } catch (e) {
          // }

          this.store.dispatch(new UserActions.UpdateUser(this.user_obj));
        })
      );
  }
}
