import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { OCT_OVERLAY_DATA, OctOverlayRef } from '@shared/services/oct-overlay.service';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';

@Component({
    selector: 'oct-search-input-overlay',
    templateUrl: './search-input-overlay.component.html',
    styleUrls: ['./search-input-overlay.component.scss']
})

export class SearchInputOverlayComponent implements OnInit {
    @ViewChild(SearchInputComponent, {static: true}) searchInput: SearchInputComponent;

    constructor(
      public octOverlayRef: OctOverlayRef,
      @Inject(OCT_OVERLAY_DATA) public data: any,
    ) {
    }

  ngOnInit() {
    // this.searchInput.value$.next(this.data.searchText || '');
  }

    public close() {
        this.octOverlayRef.close();
    }

    public onClear() {
		this.data.onSearch('');
    }

    public onSearch(val: string) {
        this.data.onSearch(val);
    }
}
