import { Injectable } from '@angular/core';
import { filter, map, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpUtilsService } from './http-utils.service';
import { UtilsService } from './utils.service';
import { ModuleUtilsService } from './module-utils.service';
import { select, Store } from '@ngrx/store';
import { CommonState, getDic, getLocation } from '@store/common';
import { LineageMappings } from '@main/lineage-dashboard/lineageMapper';
import { Locations } from '@shared/enums/locations.enum';

@Injectable({
  providedIn: 'root',
})
export class ReferenceTableService {
  API_ENDPOINT: any = 'admin/Const';

  public headers: any[];
  private tools: object;
  private values: object;
  private mapper = new LineageMappings();
  private location = '';

  constructor(
    private httpUtilsService: HttpUtilsService,
    private utilsService: UtilsService,
    private moduleUtilsService: ModuleUtilsService,
    private storeCommon: Store<CommonState>,
  ) {
    this.storeCommon
      .pipe(
        select(getDic),
        filter(dic => !!dic),
        take(1),
      )
      .subscribe(dic => {
        this.headers = dic.headers;
        this.tools = dic.tools;
        this.values = dic.values;
      });
  }

  public getDic() {
    return this.httpUtilsService.post('admin/Const', null)
      .pipe(
        map((result: any) => {
          const _data = JSON.parse(result.res);

          // make trim
          for (let i = 0; i < _data.headers.length; i++) {
            _data.headers[i].AS_FULL_TEXT = _data.headers[i].AS_FULL_TEXT.trim();
            _data.headers[i].AS_URL = _data.headers[i].AS_URL.trim();
            _data.headers[i].HAS_MULTI_VALUES = _data.headers[i].HAS_MULTI_VALUES.trim();
            _data.headers[i].ORIGINAL_VALUE = _data.headers[i].ORIGINAL_VALUE.trim();
            _data.headers[i].POSITION = _data.headers[i].POSITION.trim();
            _data.headers[i].QUERY_NUMBER = _data.headers[i].QUERY_NUMBER.trim();
            _data.headers[i].TOOL = _data.headers[i].TOOL?.trim();
            _data.headers[i].TRUNCATE_DATA = _data.headers[i].TRUNCATE_DATA.trim();
            _data.headers[i].VALUE_TO_SHOW = _data.headers[i].VALUE_TO_SHOW.trim();
            _data.headers[i].TOOL_TYPE = _data.headers[i].TOOL_TYPE.trim();
            _data.headers[i].TOOLTIP_INFORMATION = _data.headers[i].TOOLTIP_INFORMATION || null;
          }
          for (let i = 0; i < _data.tools.length; i++) {
            _data.tools[i].TOOL = _data.tools[i].TOOL?.trim();
            _data.tools[i].TOOL_NAME = _data.tools[i].TOOL_NAME?.trim();
            _data.tools[i].TOOL_TYPE = _data.tools[i].TOOL_TYPE?.trim();
          }
          for (let i = 0; i < _data.values.length; i++) {
            _data.values[i].ORIGINAL_VALUE = _data.values[i].ORIGINAL_VALUE.trim();
            _data.values[i].TOOL = _data.values[i].TOOL.trim();
            _data.values[i].VALUE_TO_SHOW = _data.values[i].VALUE_TO_SHOW.trim();
          }

          return _data;
        })
      );
  }

  public buildResults(data, isStringData: boolean = true) {
    data = this.buildResultsHeaders(data, isStringData);
    data = this.buildResultsValues(data);
    data = this.buildResultsTools(data);
    data = this.buildResultsArrayOfObjs(data);
    return data;
  }

  public buildResultsHeaders(data, isStringData) {
    let values;
    const dataResults = isStringData ? JSON.parse(data.returnData) : data.returnData;
    dataResults.forEach(value => {
      // save the original obj
      value.original_val = Object.assign({}, value);

      if (data.queryNumber === 'LINEAGE') {
        values = this.headers.filter(item => [value.tool_name?.toLowerCase(), value.ToolName?.toLowerCase(), value.TOOL_NAME?.toLowerCase()].includes(item.TOOL) &&
          item.QUERY_NUMBER == data.queryNumber && item.TOOL_TYPE === data.type);
      } else {
        values = this.headers.filter(item => [value.tool_name?.toLowerCase(), value.ToolName?.toLowerCase(), value.TOOL_NAME?.toLowerCase()].includes(item.TOOL?.toLowerCase()) && item.QUERY_NUMBER == data.queryNumber);
      }

      if (Object.keys(values).length === 0) {
      }

            // update the columns names
            const objsToDelete = [];
            values.forEach(val => {
                // names to show
                if (val.POSITION == -1 || val.POSITION == -2) {
                    if ((val.ORIGINAL_VALUE).split(';').length > 1) {
                        const arr = val.ORIGINAL_VALUE.substring(1, val.ORIGINAL_VALUE.length - 1).split(';');
                        let res = '';
                        for (let i = 0; i < arr.length; i++) {
                            res += value[arr[i]];
                            if (arr.length - 1 > i && val.POSITION == -1) {
                                res += '.';
                            } else if (arr.length - 1 > i && val.POSITION == -2) {
                                res += ' --> ';
                            }
                        }
                        value[val.POSITION + '_' + val.VALUE_TO_SHOW] = res;
                    } else {
                        value[val.POSITION + '_' + val.VALUE_TO_SHOW] = value.original_val[val.ORIGINAL_VALUE];
                    }
                } else {
                    objsToDelete.push(val.ORIGINAL_VALUE);
                    // utils.renameKeyObj(value, val.ORIGINAL_VALUE, val.VALUE_TO_SHOW);
                    value[val.VALUE_TO_SHOW] = value.original_val[val.ORIGINAL_VALUE];
                    this.utilsService.renameKeyObj(value, val.VALUE_TO_SHOW, (val.POSITION + '_' + val.VALUE_TO_SHOW));
                    value[val.POSITION + '_' + val.VALUE_TO_SHOW] = {

                        value: value[val.POSITION + '_' + val.VALUE_TO_SHOW],
                        server_name: val.ORIGINAL_VALUE
                    };
                }
            });
        });
        return dataResults;
    }

  // change names of tools. example: infa --> informatica
  public buildResultsTools(data) {
    let values;
    const data_results = data;
    data_results.forEach((value, i) => {

      let tool_val, tool_key_name;
      for (let k = 0; k < Object.keys(value).length; k++) {
        if (Object.keys(value)[k].toLowerCase().indexOf('tool') > -1 && Object.keys(value)[k].toLowerCase().indexOf('type') == -1) {
          tool_val = Object.values(value)[k];
          tool_key_name = Object.keys(value)[k];
        }
      }

      values = _.find(this.tools, function (val, key) {
        if (val['TOOL'] == tool_val.value || val['TOOL'] == tool_val) {
          return val;
        }
      });
      // update the columns names
      if (values) {
        value[tool_key_name] = values.TOOL_NAME;
        value.TOOL_TYPE = values.TOOL_TYPE;
      }
    });
    return data_results;
  }

  public buildResultsValues(data) {
    let values;
    const data_results = data;
    data_results.forEach((value, i) => {

      let tool_val, tool_key_name;
      for (let k = 0; k < Object.keys(value).length; k++) {
        if (Object.keys(value)[k].toLowerCase().indexOf('tool') > -1 && Object.keys(value)[k].toLowerCase().indexOf('type') == -1) {
          tool_val = Object.values(value)[k];
          tool_key_name = Object.keys(value)[k];
        }
      }

      values = _.find(values, function (val, key) {
        if (val['TOOL'] == tool_val) {
          return val;
        }
      });
      // update the columns names
      if (values) {
        this.utilsService.renameKeyObj(value, values.ORIGINAL_VALUE, values.VALUE_TO_SHOW);
      }
    });
    return data_results;
  }

  public buildResultsArrayOfObjs(data) {
    const data_results = [];
    let i = 0;
    _.forEach(data, val => {
      const obj = {
        original_val: val.original_val
      };
      _.forEach(val, (value, key) => {
        const get_underscore = key.indexOf('_');
        const index = key.substring(0, get_underscore);
        // shown values on screen
        if (this.utilsService.startWithNumber(key)) {
          const objKey = 'obj_' + index;
          const valKey = 'val_' + index;
          obj[valKey] = value;
          obj[objKey] = key.substr(get_underscore + 1, key.length);
        } else if (key.startsWith('-1')) { // names to show as titles
          const objKey = 'obj_-1';
          const valKey = 'val_-1';
          obj[valKey] = value;
          obj[objKey] = key;
          // } else if (typeof value == 'object' && value != null) { //source\target objects
          //    let get_underscore = Object.keys(value)[0].indexOf("_");
          //    let index = Object.keys(value)[0].substring(0, get_underscore);
          //    let key_type = Object.keys(value)[0].substring(parseInt(index) + 1);
          //    let objKey = "obj_" + keyname + "_" + index;
          //    let valKey = "val_" + keyname + "_" + index;
          //    obj[valKey] = Object.values(value)[0];
          //    obj[objKey] = key_type;
        } else if (key.startsWith('-2')) { // types to show as titles (just on db circle)
          const objKey = 'obj_-2';
          const valKey = 'val_-2';
          obj[valKey] = value;
          obj[objKey] = key;
        } else { // hidden values
          const objKey = 'hidden_obj_' + i;
          const valKey = 'hidden_val_' + i;
          obj[valKey] = value;
          obj[objKey] = key;
          i++;
        }
      });
      data_results.push(obj);
    });
    return data_results;
  }

  public buildDataToRequest(data) {
    let values;
    const data_results = [];
    // _.forEach(_data.headers, function (value, i) {
    values = _.filter(this.headers, function (val, key) {
      if (this.moduleUtilsService.shortToLongToolNameToDisplay(val['TOOL']).toUpperCase() == data.tool_name.toUpperCase() && val['QUERY_NUMBER'] == data.query_number) {
        return val;
      }
    });
    // update the columns names
    if (values) {
      for (let i = 0; i < values.length; i++) {
        data_results.push(values[i]);
      }
    }
    // })
    return data_results;
  }

  public convertObjValNumbersToKeyVal(data) {
    const arrOfObjs = [];
    for (const key in data) {
      if (key.indexOf('obj') > -1) {
        let val_text = 'val_';
        if (key.indexOf('hidden') > -1) {
          val_text = 'hidden_val_';
        }
        const obj = {};
        const index = key.substr(key.lastIndexOf('_') + 1, key.length);
        obj[data[key]] = data[val_text + index];
        arrOfObjs.push(obj);
      }
    }
    return arrOfObjs;
  }

  public buildResultsValuesRightClickForLineage(data, query_data) {
    const data_results = data;
    data_results.forEach((value, i) => {
      const headerItems = this.headers.filter((val, key) => {
        if ((value.properties && val.TOOL === value.properties.ToolName /*|| val['TOOL'] == query_data.tool_name*/) && val.QUERY_NUMBER == query_data.query_number && val.VALUE_TO_SHOW) {
          return val;
        }
      });
      // update the columns names
      if (headerItems) {
        const objsToDelete = [];
        headerItems.forEach((headerItem, key) => {
          if (headerItem.VALUE_TO_SHOW === 'Tool' && this.moduleUtilsService.getModuleToGeneralModule(headerItem.TOOL_TYPE) === this.moduleUtilsService.getModuleLongName(value.type)) {
            value[headerItem.POSITION + '_' + headerItem.VALUE_TO_SHOW] = {
              value: this.moduleUtilsService.shortToLongToolNameToDisplay(value.properties ? value.properties[headerItem.ORIGINAL_VALUE] : value[headerItem.ORIGINAL_VALUE]),
              as_full_text: headerItem.AS_FULL_TEXT,
              as_url: headerItem.AS_URL,
              as_multi_values: headerItem.HAS_MULTI_VALUES
            };
          } else {
            if (headerItem.POSITION !== '' && this.moduleUtilsService.getModuleToGeneralModule(headerItem.TOOL_TYPE) === this.moduleUtilsService.getModuleLongName(value.type)) {
              value[headerItem.POSITION + '_' + headerItem.VALUE_TO_SHOW] = {
                value: value.properties ? value.properties[headerItem.ORIGINAL_VALUE] : value[headerItem.ORIGINAL_VALUE],
                as_full_text: headerItem.AS_FULL_TEXT,
                as_url: headerItem.AS_URL,
                as_multi_values: headerItem.HAS_MULTI_VALUES
              };
            }

          }
        });
      }
    });
    return data_results;
  }

  public buildResultsValuesRightClick(data, queryData, isLineageLink?) {
    let values;
    const dataResults = data;
    let is_lineage_links = false;

    if (isLineageLink) {
      if (isLineageLink == 'links') {
        is_lineage_links = true;
      }
    }

    const vm = this;
    _.forEach(dataResults, function (value, i) {
      values = _.filter(vm.headers, function (val, key) {
        if (val['TOOL'] == queryData.tool_name && val['QUERY_NUMBER'] == queryData.query_number && val['VALUE_TO_SHOW']) {
          return val;
        }
      });
      // update the columns names
      if (values) {
        const objsToDelete = [];
        _.forEach(values, function (val, key) {

          // if this property has multi values so splited the value of it
          if (val.HAS_MULTI_VALUES == 1 && !is_lineage_links) {
            if (value[val.ORIGINAL_VALUE]) {
              value[val.ORIGINAL_VALUE] = vm.splitedHasMultiValues(value[val.ORIGINAL_VALUE]);
            } else if (value.properties[val.ORIGINAL_VALUE]) {
              value.properties[val.ORIGINAL_VALUE] = vm.splitedHasMultiValues(value.properties[val.ORIGINAL_VALUE]);
            }
          }

          // names to show
          if ((([309, 312, 'GSP'].includes(queryData.query_number))
              && val.ORIGINAL_VALUE.toLowerCase().indexOf('source') > -1)
            || queryData.query_number == 25 && val.ORIGINAL_VALUE.toLowerCase().indexOf('from_') > -1) {
            if (!value.source) {
              value.source = {};
            }
            value.source[val.POSITION + '_' + val.VALUE_TO_SHOW] = {
              value: value[val.ORIGINAL_VALUE],
              as_full_text: val.AS_FULL_TEXT,
              as_url: val.AS_URL,
              as_multi_values: val.HAS_MULTI_VALUES
            };

          } else if ((([309, 312, 'GSP'].includes(queryData.query_number))
              && val.ORIGINAL_VALUE.toLowerCase().indexOf('target') > -1)
            || queryData.query_number == 25 && val.ORIGINAL_VALUE.toLowerCase().indexOf('to_') > -1) {
            if (!value.target) {
              value.target = {};
            }
            value.target[val.POSITION + '_' + val.VALUE_TO_SHOW] = {
              value: value[val.ORIGINAL_VALUE],
              as_full_text: val.AS_FULL_TEXT,
              as_url: val.AS_URL,
              as_multi_values: val.HAS_MULTI_VALUES
            };

          } else if ((queryData.query_number == 'LINEAGE' && is_lineage_links) || [309, 312, 25, 'GSP'].includes(queryData.query_number)) { // link values
            if (val.POSITION && value[val.ORIGINAL_VALUE]) {
              if (!value.link) {
                value.link = {};
              }
              value.link[val.POSITION + '_' + val.VALUE_TO_SHOW] = {
                value: value[val.ORIGINAL_VALUE],
                as_full_text: val.AS_FULL_TEXT,
                as_url: val.AS_URL,
                as_multi_values: val.HAS_MULTI_VALUES
              };
            }
          } else {
            value[val.POSITION + '_' + val.VALUE_TO_SHOW] = {
              value: value.properties ? value.properties[val.ORIGINAL_VALUE] : value[val.ORIGINAL_VALUE],
              as_full_text: val.AS_FULL_TEXT,
              as_url: val.AS_URL,
              as_multi_values: val.HAS_MULTI_VALUES
            };
          }
        });
      }
    });
    return dataResults;
  }

  public buildResultsArrayOfObjsWithoutHiddenVals(data) {
    const data_results = [];
    const vm = this;
    _.forEach(data, function (val, key) {
      const obj = {
        data_right_click: undefined
      };
      _.forEach(val, function (value, keyname, a) {
        let i = 0;
        const get_underscore = keyname.indexOf('_');
        const index = keyname.substring(0, get_underscore);
        // shown values on screen
        if (typeof value == 'object' && value != null && (keyname == 'source' || keyname == 'target' || keyname == 'link')) { // source\target objects - col to col maps
          _.forEach(value, function (v, k) {
            const get_underscore = k.indexOf('_');
            const index = k.substring(0, get_underscore);
            const key_type = k.substring(get_underscore + 1);
            const objKey = 'obj_' + index;
            const valKey = 'val_' + index;
            if (!obj[keyname]) {
              obj[keyname] = {};
            }
            obj[keyname][valKey] = v;
            obj[keyname][objKey] = key_type;
          });
        } else if (vm.utilsService.startWithNumber(keyname)) { // table to table maps
          const objKey = 'obj_' + index;
          const valKey = 'val_' + index;
          if (!obj.data_right_click) {
            obj.data_right_click = {};
          }
          obj.data_right_click[valKey] = value;
          obj.data_right_click[objKey] = keyname.substr(get_underscore + 1, keyname.length);
        } else {
          obj[keyname] = value;
        }
        i++;
      });
      data_results.push(obj);
    });
    return data_results;
  }

  public buildResultsValuesJumpBetweenTables(data, query_data) {
    let values;
    const data_results = data;
    const vm = this;
    _.forEach(data_results, function (value, i) {
      values = _.filter(vm.headers, function (val, key) {
        if (val['TOOL'] == query_data.tool_name && val['QUERY_NUMBER'] == query_data.query_number && val['VALUE_TO_SHOW']) {
          return val;
        }
      });
      // update the columns names
      if (values) {
        _.forEach(values, function (val, key) {
          // names to show
          if (val.TRUNCATE_DATA == 1) {
            value[val.POSITION + '_' + val.VALUE_TO_SHOW] = value[val.ORIGINAL_VALUE].substring(value[val.ORIGINAL_VALUE].lastIndexOf('\\') + 1);
          } else {
            value[val.POSITION + '_' + val.VALUE_TO_SHOW] = value[val.ORIGINAL_VALUE];
          }
        });
      }
    });
    return data_results;
  }

  public getValueFromHiddenByObjectName(obj, name) {
    // get the obj
    const hiddenObj = _.findKey(obj, function (val, key) {
      let a;
      if (val == name) { // example: name = 'container_tool_type'
        a = key.lastIndexOf('_') + 1;
        return key;
      }
    });
    if (hiddenObj) {
      const num = hiddenObj.substr(hiddenObj.lastIndexOf('_') + 1);
      // get the value of this obj
      if (hiddenObj) {
        const b = _.findKey(obj, function (v, k) {
          if (k == ('hidden_val_' + num)) {
            return k;
          }
        });

        return obj[b];
      }
    }
  }

  public getTooltipInformation(data, query_data, is_node?) {
    let infoItem: any;

    this.storeCommon
      .pipe(
        select(getLocation),
        take(1),
      )
      .subscribe(location => {
        this.location = location;
      });

    if (Locations.e2eColumn === this.location) {
      data.map(dataItem => {
        const headers = this.headers.filter(headerItem =>
          [dataItem.toolName].includes(headerItem.TOOL) &&
          headerItem.TOOL_TYPE === query_data.toolType &&
          headerItem.QUERY_NUMBER === query_data.query_number &&
          headerItem.E2E_BREADCRUMBS);

        infoItem = [];
        for (const dataItemKey in dataItem) {
          const header = headers.find(item => this.utilsService.lowercaseFirstLetter(item.ORIGINAL_VALUE) === dataItemKey);
          if (header) {
            infoItem.push({
              key: header.VALUE_TO_SHOW,
              value: dataItemKey === 'toolName' ? this.moduleUtilsService.shortToLongToolNameToDisplay(dataItem[dataItemKey]) : dataItem[dataItemKey],
              position: parseInt(header.TOOLTIP_INFORMATION),
            });
          }
        }
        this.utilsService.orderByPosition(infoItem);
      });
    } else {
      let values;
      let data_results = data;
      infoItem = {};

      if (is_node) {
        if (![data_results.find(obj => obj.type === this.moduleUtilsService.getModuleToGeneralModule(this.moduleUtilsService.getToolType(query_data.tool_name)))]) {
          data_results = [data_results.find(obj => obj.type === this.moduleUtilsService.getModuleToGeneralModule(this.moduleUtilsService.getToolType(query_data.tool_name)))];
        }
      }

      const toolType = this.moduleUtilsService.getModuleShortName(query_data.toolType);

      data_results
        .filter(value => !!value)
        .forEach((value, i) => {
          values = _.filter(this.headers, (val: any, key) => {
            const headersToolType = this.moduleUtilsService.getModuleShortName(this.moduleUtilsService.getModuleToGeneralModule(val.TOOL_TYPE));
            if (is_node) {
              if (val.TOOL === data[0].tool &&
                headersToolType === toolType &&
                val.QUERY_NUMBER === query_data.query_number &&
                val.TOOLTIP_INFORMATION > 0) {
                return val;
              }
            } else if (val.TOOL === query_data.tool_name &&
              headersToolType === toolType &&
              val.QUERY_NUMBER == query_data.query_number &&
              val.TOOLTIP_INFORMATION > 0) {
              return val;
            }
          });
          // update the columns names
          if (values) {
            _.forEach(values, (val: any, key) => {
                // names to show
                const prop_val = 'val_' + [val.TOOLTIP_INFORMATION];
                const prop_obj = 'obj_' + [val.TOOLTIP_INFORMATION];

                const tool = this.moduleUtilsService.shortToLongToolNameToDisplay(val.TOOL);

                infoItem[prop_val] = {
                  value: (() => {
                    if (val.VALUE_TO_SHOW === 'Tool') {
                      return tool;
                    } else if (value.properties) {
                      return value.properties[val.ORIGINAL_VALUE];
                    } else {
                      return value[val.ORIGINAL_VALUE];
                    }
                  })(),
                  server_name: val.ORIGINAL_VALUE
                };
                infoItem[prop_obj] = val.VALUE_TO_SHOW;
              }
            );
          }
        });
    }
    return infoItem;
  }

  public createInfoData(dataItem) {
    const infoData = [];

    for (const key in dataItem) {
      let _property_key, _property_value;
      if (key.startsWith('obj_') && key.indexOf('-') === -1) {
        _property_key = dataItem[key];
        const _temp = _.findKey(dataItem, function (_val, _key) {
          if (_key.startsWith('val_') && _key.indexOf('-') === -1 && _key.indexOf(key.substr(key.indexOf('_') + 1)) > -1) {
            return _val;
          }
        });
        if (typeof dataItem[_temp] === 'object') {
          _property_value = dataItem[_temp].value;
        } else {
          _property_value = dataItem[_temp];
        }

        // show just if there is a value
        if (_property_value) {
          let keySplittedArr = key.split('_');
          let position = keySplittedArr[keySplittedArr.length - 1];
          infoData.push({
            'key': _property_key,
            'value': _property_value,
            'position': position
          });
        }
      }
    }
    this.utilsService.orderByPosition(infoData);
    return infoData;
  }

  // build data that need to show to be an object
  public buildInfoData(dataItem, isForce?) {
    dataItem.infoData = [];

    for (const key in dataItem) {
      let propertyKey, propertyValue;
      if (key.startsWith('obj_') && key.indexOf('-') === -1) {
        propertyKey = dataItem[key];
        const temp = _.findKey(dataItem, function (_val, _key) {
          if (_key.startsWith('val_') && _key.indexOf('-') === -1 && _key.indexOf(key.substr(key.indexOf('_') + 1)) > -1) {
            return _val;
          }
        });
        if (typeof dataItem[temp] === 'object') {
          propertyValue = dataItem[temp].value;
        } else {
          propertyValue = dataItem[temp];
        }

        if (!dataItem.infoData) {
          dataItem.infoData = [];
        }

        // show just if there is a value
        if (propertyValue) {
          const keySplittedArr = key.split('_');
          const position = keySplittedArr[keySplittedArr.length - 1];
          dataItem.infoData.push({
            'key': propertyKey,
            'value': propertyKey === 'Tool' ? propertyValue.toUpperCase() : propertyValue,
            'index': position
          });
        }
      }
    }
    if (dataItem.infoData) {
      dataItem.infoData.sort((a, b) => a.index - b.index);
    }

    return dataItem;
  }

  hasMapBtn(item): boolean {
    return (this.moduleUtilsService.getModuleShortName(item.TOOL_TYPE || item.module) !== 'DB') ||
      ((this.moduleUtilsService.getModuleShortName(item.TOOL_TYPE || item.module) === 'DB') &&
        (this.mapper.getMap(item.db_type) ||
          this.mapper.getMap(item.db_type?.toUpperCase()) ||
          this.mapper.getMap(item.object_type) ||
          (this.mapper.getMap(item.OBJECT_TYPE?.toUpperCase())) ||
          (this.mapper.getMap(item.OBJECT_TYPE)))
      );
  }

  // return true just for combine between SQLS (DB) and (ETL)
  public openLinkBtn(sourceObj, targetObj): boolean {
    if ((targetObj?.type === 'DATABASE'
        && sourceObj.type === 'ETL' && (['SSIS', 'ADF', 'ORACLE', 'VERTICA', 'NETEZZA', 'SQLS', 'SNOWFLAKE'].includes(sourceObj.tool))
        // don't link between sql to sql
        && (sourceObj.tool !== 'ORACLE' && sourceObj.tool !== 'VERTICA' && sourceObj.tool !== 'NETEZZA' && sourceObj.tool !== 'SQLS'
          && sourceObj.tool !== 'SNOWFLAKE')) ||
      (sourceObj?.type === 'DATABASE'
        && targetObj?.type === 'ETL' && (['SSIS', 'ADF', 'ORACLE', 'VERTICA', 'NETEZZA', 'SQLS', 'SNOWFLAKE'].includes(targetObj.tool))
        // don't link between sql to sql
        && (targetObj?.tool !== 'ORACLE' && targetObj?.tool !== 'VERTICA' && targetObj?.tool !== 'NETEZZA' && targetObj?.tool !== 'SQLS' && targetObj?.tool !== 'SNOWFLAKE'))) {
      return true;
    }
    return false;
  }

  public getHeader(key, tool, queryNumber) {
    return this.headers.find(headerItem => key.toLowerCase() === headerItem.ORIGINAL_VALUE.toLowerCase() &&
      tool.toLowerCase() === headerItem.TOOL.toLowerCase() &&
      headerItem.QUERY_NUMBER == queryNumber);
  }

  private splitedHasMultiValues(values) {
    if (values.startsWith(';')) {
      values = values.substr(1);
    }
    const splitedArr = _.uniq((values).split(';'));
    const splitedValues = splitedArr.join('<br>');
    return splitedValues;
  }
}
