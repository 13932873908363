import { AfterViewInit, Component, ElementRef, forwardRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService } from 'ngx-clipboard';
import { select, Store } from '@ngrx/store';
import { selectSearchType, selectSearchValue } from '@store/search';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchType } from '@shared/enums/searchType';
import { UtilsService } from '@core/services/utils.service';
import { Directionality } from '@angular/cdk/bidi';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AngularEditorComponent } from '@kolkov/angular-editor/lib/angular-editor.component';
import { AssetDetailsService } from '@main/adc/adc-home/asset-details/asset-details.service';

@Component({
    selector: 'oct-wysiwyg-form-control',
    templateUrl: './wysiwyg-form-control.component.html',
    styleUrls: ['./wysiwyg-form-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WysiwygFormControlComponent),
            multi: true
        }
    ],
})
export class WysiwygFormControlComponent implements OnInit, AfterViewInit {
	@ViewChild('editor', {static: true}) editor: AngularEditorComponent;
	@ViewChild('copyLabel', {static: true}) copyLabel: ElementRef;
	@ViewChild('inputLabel', {static: true}) inputLabel: ElementRef;
    @Input() formControl: FormControl;
    @Input() id: string;
    @Input() title: string;
    @Input() key: string;
    @Input() value: string;
    @Input() data: any;
    @Input() readonly: boolean;
    @Input() isUserRole: boolean;
    @Input() characterLimit: number = 0;
    saveClick: (value: string) => void;
    onValueChange: (value: any, key: string) => void;
    editMode = false;
    hoverMode: boolean = false;
    characterCount: number = 0;
    @HostBinding('class') textFormControl = 'text-form-control';
    search$: Observable<{ searchType: SearchType, searchValue: any }>;
    isRtl: boolean;

    editorConfig: AngularEditorConfig = {
        editable: false,
        showToolbar: false,
        placeholder: '',
        toolbarHiddenButtons: [
            [
                'undo',
                'redo',
                'strikeThrough',
                'subscript',
                'superscript',
                'justifyLeft',
                'justifyCenter',
                'justifyRight',
                'justifyFull',
                'indent',
                'outdent',
                'insertUnorderedList',
                'insertOrderedList',
                'heading',
                'fontName'
            ],
            [
                'fontSize',
                'textColor',
                'backgroundColor',
                'customClasses',
                'insertImage',
                'insertVideo',
                'insertHorizontalRule',
                'removeFormat',
                'toggleEditorMode'
            ]
        ]
    };

    constructor(
        private _clipboardService: ClipboardService,
        private _toaster: ToastrService,
        private store: Store<any>,
        private utilsService: UtilsService,
        private dir: Directionality,
        private assetDetailsService: AssetDetailsService,
    ) {
        this.isRtl = dir.value === 'rtl';
    }

    ngOnInit(): void {
        const a = this.store.pipe(select(selectSearchValue));
        const b = this.store.pipe(select(selectSearchType));
        this.search$ = combineLatest([a, b])
            .pipe(
                map(results => {
                    return {
                        searchValue: results[0],
                        searchType: results[1],
                    };
                })
            );

        this.assetDetailsService.editMode$.subscribe((res) => {
            if (res.editMode && res.cancelOrSaveAll.trim() === '') {
                this.onEditClick();
            } else if (!res.editMode && res.cancelOrSaveAll.trim() === 'cancel') {
                this.onCancel();
            } else if (!res.editMode && res.cancelOrSaveAll.trim() === 'save') {
                this.onSave();
            } else {
                return;
            }
        });

        this.formControl.addValidators([this.plainTextMaxLengthValidator(this.characterLimit - 1)]);

        this.formControl.valueChanges.subscribe((value: string) => {
            const plainText = this.convertHtmlToString(value);
            this.characterCount = plainText.length;
            if (plainText.length > this.characterLimit) {
                this._toaster.error('One or more fields have exceeded the character limit. Please correct and try again.');
            }
        });
    }

    ngAfterViewInit(): void {
        this._clipboardService.copyResponse$.subscribe(re => {
            if (re.isSuccess) {
                this._toaster.success('Value Copied!', 'Clipboard');
            } else {
                this._toaster.error('Value Empty!', 'Clipboard');
            }
        });

        const rtlChars = /[\u0590-\u05FF\uFB1D-\uFB4F]/;
		const element = this.editor ? this.editor.textArea.nativeElement : this.inputLabel?.nativeElement;
        this.formControl
            .valueChanges
            .subscribe((value: string) => {
                const plainText = this.convertHtmlToString(value);
                this.onValueChange(plainText, this.key);
                if (plainText.length >= this.characterLimit) {
                    this._toaster.error('One or more fields have exceeded the character limit. Please correct and try again.');
                    return;
                }
				this.isRtl = rtlChars.test(element?.innerText[0]);
				this.characterCount = element?.innerText.length;
            });
		this.isRtl = rtlChars.test(element?.innerText[0]);
	}

    private convertHtmlToString(html: string): string {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    }

    private plainTextMaxLengthValidator(maxLength: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value || '';
            const plainText = this.convertHtmlToString(value);
            return plainText.length > maxLength ? { 'plainTextMaxLength': { requiredLength: maxLength, actualLength: plainText.length } } : null;
        };
    }

    onChange: any = () => {
    };

    onTouch: any = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value): void {
        this.value = value;
        this.formControl.setValue(value);
    }

    onEditClick() {
        if (this.checkPermissions() || this.formControl.disabled) {
            return;
        }

        this.editMode = !this.editMode;
        this.editorConfig.showToolbar = this.editMode;
        this.editorConfig.editable = this.editMode;
        this.editorConfig.placeholder = this.editMode ? 'Enter text here...' : '';
		const element = this.editor ? this.editor.textArea.nativeElement : this.inputLabel?.nativeElement;
		this.characterCount = this.convertHtmlToString(element?.innerText).length;    }

    onCancel() {
        this.formControl.setValue(this.value);
        this.editMode = false;
        this.hoverMode = false;
        this.editorConfig.showToolbar = false;
        this.editorConfig.editable = false;
        this.editorConfig.placeholder = this.editMode ? 'Enter text here...' : '';
    }

    onSave() {
        const plainText = this.convertHtmlToString(this.formControl.value);
        if (plainText.length > this.characterLimit) {
            this._toaster.error('Cannot save. The text exceeds the maximum allowed length.');
            return;
        }
        this.editMode = false;
        this.hoverMode = false;
        this.editorConfig.showToolbar = false;
        this.editorConfig.editable = false;
        this.editorConfig.placeholder = this.editMode ? 'Enter text here...' : '';
        this.saveClick(this.formControl.value);
    }

    onCopyClick(e: MouseEvent) {
		const element = this.editor ? this.editor.textArea.nativeElement : this.inputLabel?.nativeElement;
		this._clipboardService.copy(this.convertHtmlToString(element?.innerText));    }

    makeHoverFunc() {
        this.hoverMode = !this.hoverMode;
    }

    checkLang(event) {
        return this.utilsService.isRightToLeftLanguage(event);
    }

    onTextChange(e: any) {
        console.log(e);
    }

    private checkPermissions(): boolean {
        return this.isUserRole;
    }
}
