import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { Locations } from '@shared/enums/locations.enum';

@Injectable()
export class ModuleUtilsService {
	constructor(
		private utilsService: UtilsService
	) {
	}

	public longToShortToolName = function (toolName) {
		let _toolName;
		switch (toolName.toUpperCase().replace('_', ' ')) {
			case 'DATA STAGE':
				_toolName = 'DS';
				break;
			case 'INFORMATICA':
				_toolName = 'INFA';
				break;
			case 'SQL SERVER':
				_toolName = 'SQLS';
				break;
			case 'POWER BI':
				_toolName = 'POWER_BI';
				break;
			case 'QLIK':
				_toolName = 'QV';
				break;
			case 'TEXTUAL FILES':
				_toolName = 'FILE';
				break;
			case 'MICROSTRATEGY':
				_toolName = 'MICROS';
				break;
			case 'AMDOCS DATA HUB':
				_toolName = 'ADH';
				break;
			case 'BUSINESS OBJECTS':
				_toolName = 'BO';
				break;
			default:
				_toolName = toolName.toUpperCase();
				break;
		}
		return _toolName;
	};

	public bgToNameToDisplay(toolName: string): string {
		if (toolName.toUpperCase().indexOf('_BG') > -1) {
			let additionBG;
			const tool = toolName.split('_');
			toolName = tool[0];
			toolName = this.shortToLongToolNameToDisplay(toolName);
			switch (toolName) {
				case 'BUSINESS OBJECTS':
					additionBG = ' UNIVERSES';
					break;
				case 'COGNOS':
					additionBG = ' MODELS';
					break;
				case 'MICROSTRATEGY':
					additionBG = ' PROJECT DETAILS';
					break;
				case 'OBIEE':
					additionBG = ' SUBJECT AREAS';
					break;
				default:
					additionBG = ' Data Dictionary';
					break;
			}

			toolName = `${toolName} ${additionBG}`;
		}

		return toolName;
	}

	public shortToLongToolNameToDisplay(toolName: string): string {
		let _toolName = '';
		if (toolName) {
			switch (toolName.toUpperCase()) {
				case 'DS':
					_toolName = 'DATASTAGE';
					break;
				case 'INFA':
					_toolName = 'INFORMATICA';
					break;
				case 'SQLS':
					_toolName = 'SQL SERVER';
					break;
				case 'QV':
					_toolName = 'QLIK';
					break;
				case 'POWER_BI':
					_toolName = 'POWER BI';
					break;
				case 'BUSINESS OBJECT':
					_toolName = 'BO';
					break;
				case 'FILE':
					_toolName = 'TEXTUAL FILES';
					break;
				case 'SQL SP':
					_toolName = 'SQL STORED PROCEDURE';
					break;
				case 'ORACLE SP':
					_toolName = 'ORACLE STORED PROCEDURE';
					break;
				case 'VERTICA SP':
					_toolName = 'VERTICA STORED PROCEDURE';
					break;
				case 'NETEZZA SP':
					_toolName = 'NETEZZA STORED PROCEDURE';
					break;
				case 'MICROS':
					_toolName = 'MICROSTRATEGY';
					break;
				case 'BO':
					_toolName = 'BUSINESS OBJECTS';
					break;
				case 'HIVE': // Related to ADH
					_toolName = 'HIVE';
					break;
				case 'LOGICAL': // Related to ADH
					_toolName = 'LOGICAL';
					break;
				default:
					_toolName = toolName.toUpperCase();
					break;
			}
		}
		return _toolName;
	}

	public getModuleToGeneralModule(moduleName: string) {
		let _moduleName;
		switch (moduleName.toUpperCase()) {
			case 'FILES':
			case 'DB':
			case 'ANALYSIS':
				_moduleName = 'DATABASE';
				break;
			default:
				_moduleName = moduleName.toUpperCase();
				break;
		}
		return _moduleName;
	}

	public getToolType(toolName: string): string {
		let _toolName;
		toolName = this.shortToLongToolNameToDisplay(toolName);
		switch (toolName.toUpperCase().replace('_', ' ')) {
			case 'DATASTAGE':
			case 'DATA STAGE':
			case 'INFORMATICA':
			case 'ODI':
			case 'SSIS':
			case 'ADF':
			case 'ADH':
			case 'OWB':
			case 'SQL STORED PROCEDURE':
			case 'AMDOCS DATA HUB':
				_toolName = 'ETL';
				break;

			case 'ORACLE':
			case 'SNOWFLAKE':
			case 'VERTICA':
			case 'NETEZZA':
			case 'SQL SERVER':
			case 'EXADATA':
			case 'DB2':
			case 'TERADATA':
			case 'REDSHIFT':
			case 'MYSQL':
			case 'POSTGRESQL':
			case 'HIVE': // Related to ADH
			case 'LOGICAL': // Related to ADH
				_toolName = 'DB';
				break;

			case 'SSAS':
			case 'TABULAR':
				_toolName = 'ANALYSIS';
				break;

			case 'TEXTUAL FILES':
				_toolName = 'FILES';
				break;

            case 'COGNOS':
            case 'MICROSTRATEGY':
            case 'OBIEE':
            case 'SSRS':
            case 'QLIK':
            case 'QLIKSENSE':
            case 'POWER BI':
            case 'POWER_BI':
            case 'POWERBI':
            case 'BUSINESS OBJECTS':
            case 'BO':
            case 'TABLEAU':
            case 'COGNOS BG':
            case 'OBIEE BG':
            case 'SSRS BG':
            case 'POWER BI BG':
            case 'QLIK BG':
            case 'BO BG':
            case 'MICROSTRATEGY BG':
            case 'LOOKER':
            case 'SIGMA':
                _toolName = 'REPORT';
                break;

			default:
				_toolName = toolName.toUpperCase();
				break;
		}
		return _toolName;
	}

	public getToolName(toolName: string): string {
		let _toolName;
		switch (toolName.toUpperCase()) {
			case 'DS':
			case 'DATASTAGE':
				_toolName = 'DATA STAGE';
				break;
			case 'INFA':
				_toolName = 'INFORMATICA';
				break;
			case 'SQLS':
				_toolName = 'SQL SERVER';
				break;
			case 'MICROS':
				_toolName = 'MICROSTRATEGY';
				break;
			case 'QV':
				_toolName = 'QLIK';
				break;
			case 'POWER_BI':
				_toolName = 'POWER BI';
				break;
			case 'FILE':
				_toolName = 'TEXTUAL FILES';
				break;
			case 'ADH':
				_toolName = 'AMDOCS DATA HUB';
				break;
			default:
				_toolName = toolName.toUpperCase();
				break;
		}
		return _toolName;
	}

	public getOrderByType(type: string): number {
		switch (type.toUpperCase().replace('_', ' ')) {
			case 'ETL':
				return 1;
			case 'DB':
				return 2;
			case 'FILES':
				return 3;
			case 'ANALYSIS':
				return 4;
			case 'REPORT':
				return 5;
			default:
				return 5;
		}
	}

	public getModuleShortName(moduleName: string): string {
		let _moduleName;
		if (moduleName) {
			switch (moduleName.toUpperCase()) {
				case 'DATABASE':
					_moduleName = 'DB';
					break;
				default:
					_moduleName = moduleName.toUpperCase();
					break;
			}
		}
		return _moduleName;
	}

	public getModuleLongName(moduleName: string): string {
		let _moduleName;
		switch (moduleName.toUpperCase()) {
			case 'DB':
				_moduleName = 'DATABASE';
				break;
			default:
				_moduleName = moduleName.toUpperCase();
				break;
		}
		return _moduleName;
	}

	getLocationNameToShow(location: string) {
		switch (location) {
			case Locations.map:
				return 'Inner System Lineage';
			case Locations.lineageSchema:
				return 'Cross System Lineage';
			case Locations.lineage:
				return 'Cross System Lineage Dashboard';
			case Locations.discovery:
				return 'Discovery';
			case Locations.adc:
				return 'Knowledge Hub';
			case 'settings':
				return 'Admin Console';
			case Locations.adcDashboard:
				return 'Insights';
			case Locations.e2eColumn:
				return 'E2E Column Lineage';
			case Locations.liveVisualizer:
				return 'Live Lineage';
			case Locations.e2eColumnDashboard:
				return 'End To End Lineage Dashboard';
			// case 'compare/compare-reports':
			// case 'compare-reports':
			// 	location = 'Cross System Report Compare';
			// 	break;
			// case 'compare/compare-etls':
			// case 'compare-etls':
			// 	location = 'Cross System ETLs Compare';
			// 	break;
		}

		// if (location.indexOf('%5bvs%5d') > -1 || location.indexOf('[vs]') > -1) {
		// 	if (location.toLowerCase().indexOf('compare-etls') > -1) {
		// 		location = 'Compare Etls';
		// 	} else {
		// 		location = 'Compare Reports';
		// 	}
		// }
		return location;
	}

	getLocationNameToShowShort(location: string) {
		switch (location.toLowerCase()) {
			case 'lineage':
				location = 'Lineage';
				break;
			case 'discovery':
				location = 'Discovery';
				break;
			case 'adc':
				location = 'ADC';
				break;
			case 'adc dashboard':
				location = 'ADC Dashboard';
				break;
			case 'e2e':
				location = 'E2E';
				break;
			case 'compare':
				location = 'Compare';
				break;
		}
		return location;
	}

	getLocationPreferredKey(location: string): Locations {
		switch (location) {
			case 'compare/compare-reports':
				location = Locations.compareReports;
				break;
			case 'compare/compare-etls':
				location = Locations.compareEtls;
				break;
			case 'e2e-column-dashboard':
				location = Locations.e2eColumnDashboard;
				break;
			case 'e2e-column':
				location = Locations.e2eColumn;
				break;
		}
		return <Locations>location;
	}

	getLocationSubString(location: string) {
		switch (location) {
			case Locations.compareReports:
				return 'CR';
			case Locations.compareEtls:
				return 'CE';
			case Locations.lineage:
			case Locations.map:
			case Locations.lineageSchema:
			case Locations.e2eColumn:
			case Locations.e2eColumnDashboard:
				return 'L';
			default:
				return location.charAt(0).toUpperCase();
		}
	}

	public getIDFromURL(_url: string) {
		let _id;
		if (_url.indexOf('lineageSchema') > -1) {
			_id = _url.slice(_url.indexOf('lineageSchema') + 14);
		} else if (_url.indexOf('map') > -1) {
			_id = _url.slice(_url.indexOf('map') + 4);
		} else if (_url.indexOf('adc') > -1) {
			_id = _url.slice(_url.indexOf('adc') + 4);
		} else if (_url.indexOf('e2e-column') > -1) {
			_id = _url.slice(_url.indexOf('e2e-column') + 11);
		} else if (_url.indexOf('e2e-column-dashboard') > -1) {
			_id = _url.slice(_url.indexOf('e2e-column-dashboard') + 21);
		} else if (_url.indexOf('live-visualizer') > -1) {
			_id = _url.slice(_url.indexOf('live-visualizer') + 18);
		}
			// else if (_url.indexOf('lineage') > -1)
		// 	_id = _url.slice(_url.indexOf('lineage') + 8);
		else if (_url.indexOf('compare') > -1) {
			if (_url.indexOf('etl') > -1) {
				_id = _url.slice(_url.indexOf('etls') + 4);
			} else if (_url.indexOf('report') > -1) {
				_id = _url.slice(_url.indexOf('reports') + 8);
			}
			if (_id) {
				_id = this.utilsService.fixUrlEncode(_id);
				if (_id.indexOf('[vs]') > -1) {
					_id = _id.slice(_id.indexOf('[vs]') + 4);
				}
			}
		}
		return _id;
	}

	public getActionFromURL(_url: string) {
		let _id;
		if (_url.indexOf('lineageSchema') > -1) {
			_id = 'lineageSchema';
		} else if (_url.indexOf('map') > -1) {
			_id = 'map';
		} else if (_url.indexOf('adc') > -1) {
			_id = 'adc';
		} else if (_url.indexOf('e2e-column') > -1) {
			_id = 'e2eColumn';
		}
		return _id;
	}
}
