import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TableToTableText } from '@core/interfaces/map-diagram-data';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { SubheaderService } from '@core/services/layout/subheader.service';
import { PageConfigService } from '@core/services/page-config.service';
import { MenuConfigService } from '@core/services/menu-config.service';
import { SplashScreenService } from '@core/services/splash-screen.service';
import { UtilsService } from '@core/services/utils.service';
import { LineageState, selectMapInfo } from '@store/lineage';
import { select, Store } from '@ngrx/store';
import { SetLineageMapInfo } from '@store/lineage/actions';
import { CommonState } from '@store/common';
import { CodePreviewService } from '@content/partials/content/general/code-preview/code-preview.service';
import { ILogData } from '@core/interfaces/log-data';
import { LogsService } from '@core/services/logs.service';
import { SideBySideService } from '@shared/components/side-by-side/side-by-side.service';
import { PropertiesObj } from '@core/interfaces/properties-btns-data';
import { ILayoutState } from '@store/layout';


@Injectable()
export class MapIDService {
    API_ENDPOINT = 'lineageGener/runMapQuery';
    public killRequest$ = new Subject();
    public mapAsJson = '';
    public arrayOfUrl: any;
    public module: string;
    public tool: string;
    public querynumber: any;
    public pageSSIS: number;
    public notExistObj: object;
    public showErrorMsg: boolean;
    public data: any;
    public data_max_count: number;
    public url: string;
    public prevUrl: string;
    public openTextView: boolean = false;
    public selectedTabIndex: number = 0;
    public showDiagram: boolean = false;
    public searchTermSubject = new Subject<string>();
    public dataToBreadcrumbs: any = {};
    editor;
    editorContent: string;
    items$ = new Subject();
    public propertiesObjSubject = new BehaviorSubject<any>({});
    private lastTrigger: string;
    private clickOnBreadcrumbsSubject: boolean;
    private runMapQuerySubscription$: Subscription;

    constructor(
        private httpUtilsService: HttpUtilsService,
        private subheaderService: SubheaderService,
        private pageConfigService: PageConfigService,
        private menuConfigService: MenuConfigService,
        private splashScreenService: SplashScreenService,
        private utilsService: UtilsService,
        private router: Router,
        private lineageStore: Store<LineageState>,
        private storeCommon: Store<CommonState>,
        private codePreviewService: CodePreviewService,
        private logsService: LogsService,
        private sideBySideService: SideBySideService,
        private store: Store<ILayoutState>
    ) {
        this.initVals();


        // this.subheaderService.clickOnBreadcrumbsSubject.subscribe(data => {
        // 	this.clickOnBreadcrumbsSubject = data;
        // })

        // go inside just if user click on the browser back\forward btns
        // or if he clicked on the breadcrubms
        this.router.events.subscribe((event) => {
            let eventUrl = '';
            if (event instanceof NavigationStart) {
                if (event['navigationTrigger'] === 'popstate') {
                    this.lastTrigger = 'popstate';

                    eventUrl = event.url;
                    if (eventUrl === '/lineage') {
                        this.menuConfigService.deepLineageUrl = null;
                    }
                }
            }
            if ((event instanceof NavigationEnd && this.lastTrigger === 'popstate')
                || this.clickOnBreadcrumbsSubject) {
                this.lastTrigger = '';
                eventUrl = event['urlAfterRedirects'];
                if (eventUrl !== undefined && eventUrl !== '/' && eventUrl.indexOf('map') > -1) {
                    const redirectUrl = eventUrl.replace('/map/', '');
                    if (!this.data) {
                        this.data = null;
                    }
                    this.showErrorMsg = false;
                    this.buildParamsToGetMap(this.utilsService.fixUrlEncode(redirectUrl), true);
                    this.getMapDiagram();
                }
            }
        });

        // this.router.events.pairwise().subscribe((event) => {
        // 	let eventUrl = '';
        // 	if (event[1] instanceof NavigationStart) {
        // 		if (event[1]['navigationTrigger'] === 'popstate') {
        // 			this.lastTrigger = 'popstate';
        //
        // 			eventUrl = event[1].url;
        // 			if (eventUrl === '/lineage') {
        // 				this.menuConfigService.deepLineageUrl = null;
        // 			}
        // 		}
        // 	}
        // 	if ((event[1] instanceof NavigationEnd && this.lastTrigger === 'popstate')
        // 		|| this.clickOnBreadcrumbsSubject) {
        // 		this.lastTrigger = '';
        // 		eventUrl = event[1]['urlAfterRedirects'];
        // 		if (eventUrl !== undefined && eventUrl !== '/' && eventUrl.indexOf('map') > -1) {
        // 			const redirectUrl = eventUrl.replace('/map/', '');
        // 			if (!this.data) {
        // 				this.data = null;
        // 			}
        // 			this.showErrorMsg = false;
        // 			this.buildParamsToGetMap(this.utilsService.fixUrlEncode(redirectUrl), true);
        // 		}
        // 	}
        // });
    }

    public getMapDiagramByIdData(colToColMap: any): Observable<any> {
        if (colToColMap.pathFolder) {
            colToColMap.pathFolder = colToColMap.pathFolder.replace(/'/g, '\'\'');
        }
        if (colToColMap.name) {
            colToColMap.name = colToColMap.name.replace(/'/g, '\'\'');
        } // for 309

        if (colToColMap.controlFlowPath && colToColMap.controlFlowPath !== -1) {
            colToColMap.controlFlowPath = colToColMap.controlFlowPath.replace(/'/g, '\'\'');
        }

        // replace tool to be toolName
        // TODO: change in the server to get "tool" and not "toolName" and "name" instead "mapName"
        if (colToColMap.queryNum === 309) {
            colToColMap.toolName = colToColMap.tool;
            colToColMap.mapName = colToColMap.name;
        }
        return this.httpUtilsService.post(this.API_ENDPOINT, colToColMap, true)
            .pipe(
                takeUntil(this.killRequest$),
                map((result: any) => {
                    const propertiesObj: PropertiesObj = {};
                    const properties = JSON.parse(result.res[0])[0];
                    if (!properties) {
                        this.showErrorMsg = true;
                    } else {
                        propertiesObj.tool = colToColMap.tool;
                        propertiesObj.path_folder = colToColMap.pathFolder;
                        propertiesObj.connectionID = properties.CONNECTION_ID;
                        propertiesObj.connection_logic_name = properties.CONN_LOGIC_NAME;
                        this.propertiesObjSubject.next(propertiesObj);
                    }
                    return result.res;
                }),
                catchError(error => {
                    this.splashScreenService.isLoaderOn = false;
                    return error;
                })
            );
    }

    public getTextDiagramByIdData(tableToTableText: TableToTableText): Observable<any> {
        return this.httpUtilsService.postData(this.API_ENDPOINT, tableToTableText, true).pipe(
            takeUntil(this.killRequest$),
            map((result: any) => {
                return result.res;
            }),
            catchError(error => {
                this.splashScreenService.isLoaderOn = false;
                return error;
            })
        );
    }

    public buildParamsToGetMap(urlRedirect?: string, forceBred?: boolean): void {
        const redirectUrl = (urlRedirect ? urlRedirect : null);
        this.arrayOfUrl = JSON.parse(this.pageConfigService.decodeBtoa(redirectUrl != null ? redirectUrl : this.url));

        this.module = this.arrayOfUrl['module'];
        this.tool = this.arrayOfUrl['tool'];
        this.querynumber = this.arrayOfUrl['queryNum'];

        if (redirectUrl == null || forceBred) {
            this.setBreadCrumbs();
        }

        const mapInfo = {
            name: this.arrayOfUrl['name'],
        };
        this.lineageStore.dispatch(new SetLineageMapInfo(mapInfo));
    }

    public getMapDiagram(): void {
        if (['SSIS', 'ADF'].includes(this.tool)) {
            if (this.querynumber === 25 || this.querynumber === 26) { // second level of SSIS (loop container)
                this.pageSSIS = 2;
            } else if (this.querynumber === 312) {
                this.pageSSIS = 3;
            } else { // 24 + 23 - first level of SSIS (control flow)
                this.pageSSIS = 1;
            }
        }

        this.splashScreenService.setLoaderOn();

        let currentMapName;
        this.lineageStore
            .pipe(
                select(selectMapInfo),
                take(1),
            )
            .subscribe(mapInfo => currentMapName = mapInfo.name);

        let url = this.arrayOfUrl.url.substr(this.arrayOfUrl.url.indexOf('/') + 5, this.arrayOfUrl.url.length);
        // const script = this.editor.content;
        // if (script !== '') {
        //   url += '?script=' + script;
        // }
        // console.log('url', url);
        const log: ILogData = {
            usage_id: '-1',
            search_value: this.arrayOfUrl.name,
            module: 'Inner System Lineage',
            activity: 'Show Inner System Lineage',
            url,
            tool_type: this.arrayOfUrl.module,
            tool_name: this.arrayOfUrl.tool
        };
        this.logsService.writeToUsage(log);

        if (this.mapAsJson !== '') {
            this.buildNotExistObj('map', true, false);
            this.createMapSchema(this.data);
        } else {
            if (this.runMapQuerySubscription$) {
                this.runMapQuerySubscription$.unsubscribe();
            }

            this.runMapQuerySubscription$ = this.getMapDiagramByIdData(this.arrayOfUrl)
                .subscribe(this.processDiagramResults.bind(this));
        }
    }

    buildNotExistObj(page: string, isValid: boolean, isTooBig: boolean): void {
        this.notExistObj = {
            page: page,
            tooBig: isTooBig,
            valid: isValid
        };

        if (isTooBig || !isValid) {
            this.showErrorMsg = true;
            this.splashScreenService.isLoaderOn = false;
            this.notExistObj = Object.assign(this.notExistObj, this.arrayOfUrl);
        } else {
            this.showErrorMsg = false;
            this.splashScreenService.isLoaderOn = true;
        }
    }

    createMapSchema(results) {
        this.buildNodesAndLinks(results);
    }

    buildNodesAndLinks(data): void {
        setTimeout(() => {
            this.data = this.sideBySideService.setHighlight(data, this.editor);
        }, 0);
    }

    public initVals(): void {
        this.arrayOfUrl = null;
        this.module = null;
        this.tool = null;
        this.querynumber = null;
        this.pageSSIS = null;
        this.notExistObj = null;
        this.showErrorMsg = false;
        this.url = null;
        this.searchTermSubject.next('');
        this.codePreviewService.setScriptOnTextTabOfMap('Loading...');
    }

    public killRequests() {
        this.killRequest$.next();
    }

    private setBreadCrumbs(): void {
        this.dataToBreadcrumbs = this.arrayOfUrl;
        this.dataToBreadcrumbs.url = '/map/' + this.url;
    }

    private processDiagramResults(response) {
        if (typeof response === 'string') { // map is too big
            this.buildNotExistObj(response[0], true, true);
            this.subheaderService.appendBreadcrumbs(this.dataToBreadcrumbs);
            return;
        }

        // map not exist
        if (response[0] === '[]' || response[0] === 'null') {
            this.subheaderService.appendBreadcrumbs(this.dataToBreadcrumbs);
            this.buildNotExistObj('map', false, false);

        } else { // show map schema
            this.buildNotExistObj('map', true, false);
            response[0] = JSON.parse(response[0]);
            this.createMapSchema(response[0]);
        }
    }
}
