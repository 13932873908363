import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Locations } from '@shared/enums/locations.enum';
import { filterNames } from '@core/enum/recent-activities';
import { filter, take } from 'rxjs/operators';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { select, Store } from '@ngrx/store';
import { getEmail } from '@core/states/user.state';
import { getPublicSettings } from '@store/common';
import { IPublicSettings } from '@core/interfaces/store-data';
import { UtilsService } from '@core/services/utils.service';
import { ConnectionsList } from '@core/services/connections-list.service';
import { ModuleUtilsService } from '@core/services/module-utils.service';

@Component({
  selector: 'oct-time-line-filter',
  templateUrl: './time-line-filter.component.html',
  styleUrls: ['./time-line-filter.component.scss'],
})
export class TimeLineFilterComponent implements OnInit {
  @Input() email;
  @Input() limit;
  @Output() filter = new EventEmitter();
  public filters: Array<{ name: string, values: Array<any> }>;
  private modules: Array<string>;
  private connections_list: any;

  constructor(
    private httpUtilsService: HttpUtilsService,
    private store: Store<any>,
    private utilsService: UtilsService,
    private connectionsList: ConnectionsList,
    private moduleUtilsService: ModuleUtilsService,
  ) {
  }

  ngOnInit(): void {
    this.store
      .pipe(
        select(getEmail),
        take(1)
      )
      .subscribe(email => {
        this.email = email;
      });

    this.store
      .pipe(
        select(getPublicSettings),
        filter((publicSettings: IPublicSettings) => !!publicSettings && Object.keys(publicSettings).length !== 0),
        take(1)
      )
      .subscribe((publicSettings: IPublicSettings) => {
        this.modules = publicSettings.showModules.split(';');
        this.limit = publicSettings.recentActivitiesLimit;
      });

    this.connectionsList.getConnectionsFromServer()
      .subscribe(res => {
        this.filters = [
          {name: 'Sub Module', values: []},
          {name: 'Activity', values: []},
          {name: 'Tool Name', values: []},
          {name: 'Tool Type', values: []}
        ];
        this.connections_list = res;
        this.createFilterModulesList();
        this.createFilterActivityList();
        this.createFilterToolsList();
      });
  }

  public onFilter(e) {
    event.stopPropagation();
    const payload = {
      username: this.email,
      limit: this.limit,
      toolType: -1,
      toolName: -1,
      module: -1,
      activity: -1
    };
    this.filters.forEach(filterArr => {
      const checkedItems = filterArr.values
        .filter(obj => obj.checked)
        .map(val => val.text);

      if (checkedItems.length > 0) {
        if (checkedItems.find(name => name === 'Cross System Lineage Dashboard')) {
          checkedItems.push('Cross System Lineage Dashboard - Inner search bar');
          checkedItems.push('Cross System Lineage Dashboard - Top search bar');
          checkedItems.push('Cross System Lineage Dashboard - Search bar');
        }
        if (checkedItems.find(name => name === 'End To End Lineage Dashboard')) {
          checkedItems.push('End To End Lineage Dashboard - Inner search bar');
          checkedItems.push('End To End Lineage Dashboard - Top search bar');
          checkedItems.push('End To End Lineage Dashboard - Search bar');
        }
        if (checkedItems.find(name => name === 'Discovery Button')) {
          checkedItems.push('Show Button Data');
        }
        payload[filterNames[filterArr.name]] = checkedItems.join(',');
      }
    });

    this.filter.emit(payload);
  }

  public onCheckFilter(item) {
    item.checked = !item.checked;
  }

  private createFilterModulesList() {
    const arr = [];
    this.modules.forEach((module, key) => {
      if (module !== '') {
		  switch (module.toLowerCase()) {
			  case Locations.lineage:
				  arr.push({text: 'Cross System Lineage Dashboard', key: key, checked: false});
				  arr.push({text: 'Cross System Lineage', key: key, checked: false});
				  arr.push({text: 'Inner System Lineage', key: key, checked: false});
				  break;
			  case Locations.e2e:
				  arr.push({text: 'End To End Lineage Dashboard', key: key, checked: false});
				  arr.push({text: 'E2E Column Lineage', key: key, checked: false});
				  break;
			  case Locations.adc:
				  arr.push({text: 'Knowledge Hub', key: key, checked: false});
				  break;
			  case 'adc dashboard':
				  arr.push({text: 'Insights Dashboard', key: key, checked: false});
				  break;
			  case 'livelineage':
				  arr.push({text: 'Live Lineage', key: key, checked: false});
				  break;
			  case 'usage':
				  arr.push({text: 'Operational Metadata', key: key, checked: false});
				  break;
			  default:
				  arr.push({text: this.utilsService.uppercaseFirstLetter(module), key: key, checked: false});
		  }
      }
    });
    const _index = this.filters.findIndex(item => item.name === 'Sub Module');
    this.filters[_index].values = arr;
  }

  // create the filter list of 'activity'
  private createFilterActivityList() {
    const arr: Array<object> = [];
    this.modules.forEach((module, key) => {
      switch (module.toLowerCase()) {
        case Locations.lineage:
          arr.push({text: 'Search', key: key, checked: false});
          arr.push({text: 'Show Cross System Lineage', key: key, checked: false});
          arr.push({text: 'Show Inner System Lineage', key: key, checked: false});
          break;
        case Locations.e2e:
          arr.push({text: 'Show E2E Column Lineage', key: key, checked: false});
          break;
        case Locations.adc:
          arr.push({text: 'Show Asset Details', key: key, checked: false});
          break;
        case 'adc dashboard':
          arr.push({text: 'Dashboard View', key: key, checked: false});
          break;
        case Locations.discovery:
          arr.push({text: 'Discovery Button', key: key, checked: false});
      }
    });
    const _index = this.filters.findIndex(item => item.name === 'Activity');
    this.filters[_index].values = arr;
  }

  // create the filter list of 'tool name' + 'tool type'
  private createFilterToolsList() {
    const arrOfToolsName: Array<any> = [];
    const arrOfToolsType: Array<any> = [];
    this.connections_list.forEach((item) => {
      let exist = arrOfToolsType.find(_item => this.moduleUtilsService.getModuleShortName(_item.text) === item.ID.substr(0, item.ID.indexOf('_')));
      if (!exist) {
        arrOfToolsType.push({
          text: this.moduleUtilsService.getModuleLongName(item.ID.substr(0, item.ID.indexOf('_'))),
          key: arrOfToolsType.length,
          checked: false
        });
      }
      exist = arrOfToolsName.find(_item => this.utilsService.replaceUnderscoreWithSpaceChar(this.moduleUtilsService.longToShortToolName(_item.text)) === this.utilsService.replaceUnderscoreWithSpaceChar(this.moduleUtilsService.longToShortToolName(item.ID.substr(item.ID.indexOf('_') + 1, item.ID.length))));
      if (!exist) {
        arrOfToolsName.push({
          text: this.moduleUtilsService.shortToLongToolNameToDisplay(this.utilsService.replaceUnderscoreWithSpaceChar(item.ID.substr(item.ID.indexOf('_') + 1, item.ID.length))),
          key: arrOfToolsName.length,
          checked: false
        });
      }
    });

    let index = this.filters.findIndex(item => item.name === 'Tool Name');
    this.filters[index].values = arrOfToolsName;
    index = this.filters.findIndex(item => item.name === 'Tool Type');
    this.filters[index].values = arrOfToolsType;
  }
}
