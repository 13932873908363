<div *ngIf="accordionData else noData" class="card-container">
	<mat-card *ngFor="let section of accordionData" class="custom-card">
		<mat-card-header>
			<mat-card-title>{{ section.title }}</mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<mat-card-content *ngFor="let field of section.fields">
			<div class="flex space-between">
				<label class="sub-title">{{ field.label }}:</label>
				<label class="field-value">{{ field.value }}</label>
			</div>
		</mat-card-content>
	</mat-card>
</div>

<ng-template #noData class="m-widget5 group">No Data Available Yet</ng-template>
