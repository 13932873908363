import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PropertiesBtnsService } from '@shared/services/properties-btns.service';
import { UsagePayload } from '@shared/enums/usage';
import { getLineagePropertiesObj } from '@store/lineage';
import { getUser } from '@core/states/user.state';
import { selectE2EPropertiesObj } from '@store/e2e';

@Component({
	selector: 'oct-usage-content',
	templateUrl: './usage-content.component.html',
	styleUrls: ['./usage-content.component.scss', '../list-properties.component.scss']
})
export class UsageContentComponent implements OnInit {
	usageData: any = {}; // Placeholder for the data

	// Structured data for rendering the accordion
	public accordionData: Array<{ title: string, fields: Array<{ label: string, value: any }> }> = [];

	constructor(
		private store: Store<any>,
		private propertiesBtnsService: PropertiesBtnsService
	) { }

	ngOnInit(): void {
		const selectorA = this.store.pipe(select(getLineagePropertiesObj));
		const selectorB = this.store.pipe(select(getUser));
		const selectorC = this.store.pipe(select(selectE2EPropertiesObj));
		combineLatest([selectorA, selectorB, selectorC])
			.pipe(
				switchMap((results: any) => {
					const usagePayload: UsagePayload = {
						tool: results[0]?.tool || results[2]?.toolName,
						queryNum: 2,
						containerObjPath: results[0]?.path_folder || results[2]?.containerObjectPath,
						connLogicName: results[0]?.connection_logic_name || results[2]?.connLogicName,
						userId: results[1].id
					};
					return this.propertiesBtnsService.getUsageData(usagePayload);
				})
			)
			.subscribe((data: any) => {
				this.usageData = JSON.parse(data.res)[0];
				this.accordionData = [
					{title: 'Usage & Consumption', fields: [{ label: 'Last Run Date', value: this.usageData?.['Last Run Date']  }, { label: 'Last Run By', value: this.usageData?.['Last Run By']}, { label: 'Run Count', value: this.usageData?.['Run Count']}]},
					{title: 'Performance Metrics', fields: [{ label: 'Median Run Time', value: `${this.usageData?.['Median Run Time']} (sec)`}, { label: 'Last Run Duration', value: `${this.usageData?.['Last Run Duration']} (sec)`}]},
					{title: 'Audit Information', fields: [{ label: 'Last Change Date', value: this.usageData?.['Last Change Date']}, { label: 'Last Changed By', value: this.usageData?.['Last Change By']}]}
				];
			});
	}
}
