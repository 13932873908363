import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';

import { UtilsService } from '@core/services/utils.service';
import { map } from 'rxjs/operators';
import { ModuleUtilsService } from '@core/services/module-utils.service';
import { moduleType } from '@shared/enums/module-type.enum';
import { buttonsNames, schemaType, subSchemaType, tableAs } from '@core/enum/properties-btns';
import { SpinnerButtonOptions } from '@content/partials/content/general/spinner-button/button-options.interface';
import { ReferenceTableService } from '@core/services/reference-table.service';
import { MapIDService } from '@main/maps/map-id/map-id.service';
import { PageConfigService } from '@core/services/page-config.service';
import { SideBarService } from '@core/services/layout/side-bar.service';
import {
  ColToColMapETL,
  ColToColMapETLDrill,
  ColToColMapETLDrillLeftRight,
  ColToColMapLinkETLandDB,
  ColToColMapREPORT,
  MapObjs,
  TableToTableMap
} from '@core/interfaces/map-diagram-data';
import { MapsService } from '@main/maps/maps.service';
import { LineageService } from '@main/lineage-dashboard/lineage.service';
import { LineageIDService } from '@main/lineage-diagram/lineage-id/lineage-id.service';
import { LineageState } from '@store/lineage';
import * as LineageActions from '@store/lineage/actions';
import { DrillBetweenMapsService } from '@main/maps/drill-between-maps/drill-between-maps.service';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { DrillBetweenMapsComponent } from '@main/maps/drill-between-maps/drill-between-maps.component';
import { SplashScreenService } from '@core/services/splash-screen.service';
import { Locations } from '@shared/enums/locations.enum';
import { Observable } from 'rxjs';

@Injectable()
export class PropertiesBtnsService {
  public propertiesBtns: any = [];
  private propertiesBtnList: any; // PropertiesBtn[];
  private disableButtons: boolean = false;
  private showModalResults: boolean;
  private API_ENDPOINT = 'lineage/search';

  constructor(
    private utilsService: UtilsService,
    private moduleUtilsService: ModuleUtilsService,
    private referenceTableService: ReferenceTableService,
    private mapIDService: MapIDService,
    private pageConfigService: PageConfigService,
    private sideBarService: SideBarService,
    private mapsService: MapsService,
    private lineageService: LineageService,
    private lineageIDService: LineageIDService,
    private storeLineage: Store<LineageState>,
    private drillBetweenMapsService: DrillBetweenMapsService,
    private toastrService: ToastrService,
    private httpUtilsService: HttpUtilsService,
    private dialog: MatDialog,
    private splashScreenService: SplashScreenService,
  ) {
    this.showModalResults = false;
  }

  public initPropertiesBtnsList(): void {
    // all the btns - lineage and maps
    this.propertiesBtnList = [
      // SSIS btns
      {
        title: buttonsNames.PackageView,
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Map],
        subSchemaType: subSchemaType[subSchemaType.ExecuteTask], // 27 - SSIS, ADF second level
        tool: ['SSIS', 'ADF'],
        click: 'goToThirdLevel',
        dataObj: [],
        btnType: 'Map',
        clickOn: 'node'
      },
      {
        id: 'Inner_System_Container_View',
        title: buttonsNames.SequenceContainerView,
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Map],
        subSchemaType: subSchemaType[subSchemaType.ControlFlow], // 25 - SSIS second level
        tool: ['SSIS', 'ADF'],
        click: 'goToSSISSecondLevel',
        dataObj: [],
        btnType: 'Map',
        clickOn: 'node'
      },
      {
        id: 'Inner_System_Lineage',
        title: buttonsNames.MapView,
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Map],
        subSchemaType: subSchemaType[subSchemaType.LoopContainer], // 312 - etl col to col
        tool: ['SSIS', 'ADF'],
        click: 'goToColToCol',
        dataObj: [],
        btnType: 'Map',
        clickOn: 'node',
        icon: 'assets/inner-system.svg'
      },
      {
        title: buttonsNames.PackageView,
        tooltip: 'Package Flow Lineage',
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Lineage],
        subSchemaType: subSchemaType[subSchemaType.LoopContainer], // 23 - SSIS first level
        mainCircle: true,
        tool: ['SSIS'],
        click: 'goToSSISFirstLevel',
        dataObj: [],
        btnType: 'Map',
        clickOn: 'node'
      },
      {
        title: buttonsNames.PackageView,
        tooltip: 'Pipeline View',
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Lineage],
        subSchemaType: subSchemaType[subSchemaType.LoopContainer], // 23 - SSIS first level
        mainCircle: true,
        tool: ['ADF'],
        click: 'goToSSISFirstLevel',
        dataObj: [],
        btnType: 'Map',
        clickOn: 'node'
      },
      {
        id: 'Cross System Lineage',
        title: buttonsNames.LineageETL,
        tooltip: 'Cross System Lineage',
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Lineage],
        subSchemaType: subSchemaType[subSchemaType.LoopContainer], // 307
        tool: ['SSIS', 'ADF'],
        click: 'goToLineage',
        dataObj: [],
        btnType: 'Lineage',
        clickOn: 'node',
        notSSIS: true
      },
      {
        id: 'Cross System Lineage',
        title: buttonsNames.LineageReport,
        tooltip: 'Cross System Lineage',
        module: moduleType[moduleType.REPORT],
        schemaType: schemaType[schemaType.Lineage],
        subSchemaType: subSchemaType[subSchemaType.LoopContainer], // 306
        tool: [],
        click: 'goToLineage',
        dataObj: [],
        btnType: 'Lineage',
        clickOn: 'node',
        notSSIS: true
      },
      {
        id: 'Cross_System_Lineage_Radial_Inner_System',
        title: buttonsNames.ReportView,
        tooltip: 'Inner System Lineage',
        module: moduleType[moduleType.REPORT],
        schemaType: schemaType[schemaType.Lineage],
        subSchemaType: undefined, // 309 - etl col to col
        tool: ['SSIS'],
        click: 'goToColToCol',
        dataObj: [],
        btnType: 'Map',
        clickOn: 'node',
        notSSIS: true,
        tableAs: ''
      },
      // map btns from discovery
      {
        id: 'Cross_System_Lineage_Radial_Inner_System',
        title: buttonsNames.MapView,
        // module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Discovery],
        subSchemaType: undefined, // 312 - etl col to col
        tool: [],
        click: 'goToColToCol',
        dataObj: [],
        btnType: 'Map',
        clickOn: 'node'
      },
      // map btns from lineage
      {
        id: 'Cross_System_Lineage_Radial_Inner_System',
        title: buttonsNames.MapView,
        module: moduleType[moduleType.ETL],
        tooltip: 'Inner System Lineage',
        schemaType: schemaType[schemaType.Lineage],
        subSchemaType: undefined, // 312 - etl col to col
        tool: ['SSIS'],
        click: 'goToColToCol',
        dataObj: [],
        btnType: 'Map',
        clickOn: 'node',
        notSSIS: true,
        tableAs: ''
      },
      {
        id: 'Inner_System_Lineage',
        title: buttonsNames.MapView,
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Map],
        subSchemaType: undefined, // 312 - etl col to col
        tool: ['SSIS', 'ADF'],
        click: 'goToColToCol',
        dataObj: [],
        btnType: 'Map',
        clickOn: 'node'
      },
      // {
      // 	title: "Text view",
      // 	module: moduleType[moduleType.ETL],
      // 	schemaType: schemaType[schemaType.Lineage],
      // 	subSchemaType: undefined, // 310 - text query of object
      // 	tool: ["SQL", "TERADATA", "ORACLE"],
      // 	click: "goToColToCol",
      // 	dataObj: [],
      // 	btnType: "Text",// this is the text of the map
      // 	clickOn: "node",
      // 	tableAs: ""
      // },
      // lineage btns

      {
        id: 'Cross System Lineage',
        title: buttonsNames.LineageObject,
        module: moduleType[moduleType.DB],
        tooltip: 'Cross System Lineage',
        schemaType: schemaType[schemaType.Lineage],
        subSchemaType: undefined, // 308
        tableAs: tableAs[tableAs.Target],
        tool: [], // for all the tools
        click: 'goToLineage',
        dataObj: [],
        btnType: 'Lineage',
        clickOn: 'node',
        icon: 'assets/inner-system.svg'
      },

      // drill btns
      {
        title: buttonsNames.Back,
        tooltip: 'Backward : All Inner System Lineages in which the object is a target (Except for current Inner System Lineage)',
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Map],
        subSchemaType: null, // 319 - left / 318 - right
        tableAs: tableAs[tableAs.Source],
        tool: [], // for all the tools
        click: 'goToDrillRightLeft',
        dataObj: [],
        btnType: 'Map',
        clickOn: 'node',
        num: 1,
        icon: 'assets/c2c/arrow-left.svg',
      },
      {
        title: buttonsNames.Fwd,
        tooltip: 'Forward : All Inner System Lineages in which the object is a source (Except for current Inner System Lineage)',
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Map],
        subSchemaType: undefined, // 318
        tableAs: tableAs[tableAs.Source],
        tool: [], // for all the tools
        click: 'goToDrillRightLeft',
        dataObj: [],
        btnType: 'Map',
        num: 2,
        clickOn: 'node',
        icon: 'assets/c2c/arrow-right.svg',
      },
      {
        id: 'Inner_System_Back',
        title: buttonsNames.Back,
        tooltip: 'Backward : All Inner System Lineages in which the object is a target (Except for current Inner System Lineage)',
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Map],
        subSchemaType: undefined, // 319 - left / 318 - right
        tableAs: tableAs[tableAs.Target],
        tool: [], // for all the tools
        click: 'goToDrillRightLeft',
        num: 3,
        dataObj: [],
        btnType: 'Map',
        clickOn: 'node',
        icon: 'assets/c2c/arrow-left.svg',
      },
      {
        title: buttonsNames.Fwd,
        tooltip: 'Forward : All Inner System Lineages in which the object is a source (Except for current Inner System Lineage)',
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Map],
        subSchemaType: undefined, // 318
        tableAs: tableAs[tableAs.Target],
        tool: [], // for all the tools
        click: 'goToDrillRightLeft',
        num: 4,
        dataObj: [],
        btnType: 'Map',
        clickOn: 'node',
        icon: 'assets/c2c/arrow-right.svg',
      },
      {
        title: buttonsNames.Back,
        tooltip: 'Backward : All Inner System Lineages in which the object is a target (Except for current Inner System Lineage)',
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Map],
        subSchemaType: undefined, // 319 - left / 318 - right
        tableAs: tableAs[tableAs.Transform],
        tool: [], // for all the tools
        click: 'goToDrillRightLeft',
        num: 5,
        dataObj: [],
        btnType: 'Map',
        clickOn: 'node',
        icon: 'assets/c2c/arrow-left.svg',
      },
      {
        title: buttonsNames.Fwd,
        tooltip: 'Forward : All Inner System Lineages in which the object is a source (Except for current Inner System Lineage)',
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Map],
        subSchemaType: undefined, // 318
        tableAs: tableAs[tableAs.Transform],
        tool: [], // for all the tools
        click: 'goToDrillRightLeft',
        num: 6,
        dataObj: [],
        btnType: 'Map',
        clickOn: 'node',
        icon: 'assets/c2c/arrow-right.svg',
      },
      // link in lineage
      {
        title: buttonsNames.MapView,
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Lineage],
        subSchemaType: undefined, // 320
        tableAs: '',
        tool: [], // for all the tools
        click: 'goToLinkETLandDB',
        dataObj: [],
        btnType: 'Map',
        clickOn: 'link',
        icon: 'assets/inner-system.svg'
      },
      // lineage btns from map
      {
        id: 'Cross System Lineage',
        title: buttonsNames.LineageObject,
        tooltip: 'Cross System Lineage',
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Map],
        subSchemaType: undefined, // 308
        tableAs: tableAs[tableAs.Target],
        tool: [], // for all the tools
        click: 'goToLineage',
        dataObj: [],
        btnType: 'Lineage',
        clickOn: 'node',
        icon: 'assets/cross-system.svg',
      },
      {
        id: 'Cross System Lineage',
        title: buttonsNames.LineageObject,
        tooltip: 'Cross System Lineage',
        module: moduleType[moduleType.ETL],
        schemaType: schemaType[schemaType.Map],
        subSchemaType: undefined, // 308
        tableAs: tableAs[tableAs.Transform],
        tool: [], // for all the tools
        click: 'goToLineage',
        dataObj: [],
        btnType: 'Lineage',
        clickOn: 'node',
        icon: 'assets/cross-system.svg',
      },
      // expand more btn - for all nodes in lineage
      {
        id: 'Cross_System_Lineage_Radial_Expand_Left',
        title: buttonsNames.Expand,
        tooltip: 'Show more Source Objects',
        schemaType: schemaType[schemaType.Lineage],
        tool: [],
        click: 'getExpandMoreNodes',
        dataObj: [],
        btnType: 'LineageExpand',
        clickOn: 'node',
        direction: 'in'
      },
      {
        id: 'Cross_System_Lineage_Radial_Expand_Right',
        title: buttonsNames.Expand,
        // module: moduleType[moduleType.ETL],
        tooltip: 'Show more Target Objects',
        schemaType: schemaType[schemaType.Lineage],
        tool: [],
        click: 'getExpandMoreNodes',
        dataObj: [],
        btnType: 'LineageExpand',
        clickOn: 'node',
        direction: 'out'
      }
    ];
  }

  public getPropertyButtonByTool(propertiesObj): void {
    const secondFilter = [];
    for (let i = 0; i < this.propertiesBtns[0].length; i++) {
      if (this.propertiesBtns[0][i]['tool'].includes(propertiesObj.tool)) {
        secondFilter.push(this.propertiesBtns[0][i]);
      }
    }
    this.propertiesBtns[0] = secondFilter;
  }

  public getCorrectPropertiesBtn(propertiesObj, location): void {
    this.initPropertiesBtnsList();
    this.propertiesBtns = [];

    // filter --> find all
    if (location) {
      switch (location) {
        case Locations.map:
          if (propertiesObj.tool === 'SSIS' && (propertiesObj.iconType === 'Data Flow Task' ||
            propertiesObj.iconType === 'Execute SQL Task')) {
            this.propertiesBtns.push(_.filter(this.propertiesBtnList, {
              'subSchemaType': undefined,
              'schemaType': this.utilsService.uppercaseFirstLetter(location)
            }));
            this.getPropertyButtonByTool(propertiesObj);
          }
          // ssis table to table btns

          // query 25
          if (['SSIS', 'ADF'].includes(propertiesObj.tool) &&
            (propertiesObj.iconType === 'ForEach' || propertiesObj.iconType === 'ExecutePipeline' ||
              propertiesObj.iconType === 'Until' || propertiesObj.iconType === 'IfCondition' ||
              propertiesObj.iconType === 'Data Flow Task' || propertiesObj.iconType === 'Execute SQL Task' ||
              propertiesObj.iconType === 'Sequence Container' || propertiesObj.iconType === 'For Loop Container' ||
              propertiesObj.iconType === 'Foreach Loop Container')) {
            this.propertiesBtns.push(_.filter(this.propertiesBtnList, {
              'subSchemaType': 'ControlFlow',
              'schemaType': this.utilsService.uppercaseFirstLetter(location)
            }));
            this.getPropertyButtonByTool(propertiesObj);
          }
          if (propertiesObj.tool === 'ADF' && (propertiesObj.iconType === 'Copy' || propertiesObj.iconType === 'ExecuteDataFlow' ||
            propertiesObj.iconType === 'Lookup')) {
            this.propertiesBtns.push(_.filter(this.propertiesBtnList, {
              'subSchemaType': undefined,
              'schemaType': this.utilsService.uppercaseFirstLetter(location)
            }));
            this.getPropertyButtonByTool(propertiesObj);
          } else if (propertiesObj.isData !== '0' && propertiesObj.objectClicedkOn === 'node'
            && propertiesObj.as && this.mapIDService.arrayOfUrl['module'] === 'ETL'
            || this.mapIDService.arrayOfUrl['module'] === 'DATABASE') {
            this.propertiesBtns.push(_.filter(this.propertiesBtnList, (btn) => {
              if (btn.module === 'ETL' && btn.tableAs === propertiesObj.as && btn.schemaType === this.utilsService.uppercaseFirstLetter(location)) {
                if (propertiesObj.tool === 'SSAS' && btn.title === 'Lineage Object') {
                  return btn.btnType === this.utilsService.uppercaseFirstLetter(Locations.lineage) && ['Dimension', 'MeasureGroup'].includes(propertiesObj.objType);
                } else {
                  return true;
                }
              } else {
                return false;
              }
            }));
          } else if (propertiesObj.objectClicedkOn === 'link') {
            // dont' need to be btns
          }
          break;
        case Locations.lineage:
          let filterPartA;

          // user click on the link between nodes in the lineage
          if (propertiesObj.linkType && this.referenceTableService.openLinkBtn(propertiesObj.source, propertiesObj.target)) {
            this.propertiesBtns.push(_.filter(this.propertiesBtnList, {'clickOn': 'link'}));
          } else if (propertiesObj.module === 'DATABASE' && propertiesObj.tool !== 'FILE' && propertiesObj.tool !== 'HIVE' && // click on node - but DB node type view or sp have map btn
            propertiesObj.tool !== 'LOGICAL' && propertiesObj.tool !== 'TABULAR' && !propertiesObj.main_circle &&
            propertiesObj.db_type.toLowerCase() !== 'table') {
            filterPartA = _.filter(this.propertiesBtnList, {
              'schemaType': this.utilsService.uppercaseFirstLetter(location),
              'subSchemaType': undefined,
              'clickOn': 'node'
            });
            this.propertiesBtns.push(_.filter(filterPartA, function (btn) {
              return btn.module !== 'REPORT';
            }));
          } else if (propertiesObj.module === 'DATABASE' && propertiesObj.tool !== 'FILE' && propertiesObj.tool !== 'TABULAR' &&
            propertiesObj.main_circle && propertiesObj.db_type.toLowerCase() !== 'table') {
            // click on node - main circle - but DB node type view or sp have map btn
            filterPartA = _.filter(this.propertiesBtnList, {
              'schemaType': this.utilsService.uppercaseFirstLetter(location),
              'subSchemaType': undefined,
              'clickOn': 'node',
              'tableAs': ''
            });
            this.propertiesBtns.push(_.filter(filterPartA, function (btn) {
              return btn.module !== 'REPORT';
            }));
          } else if (propertiesObj.module === 'DATABASE' && !propertiesObj.main_circle) {
            // click on node - but DB node (DB don't have map at all)
            this.propertiesBtns.push(_.filter(this.propertiesBtnList, {
              'schemaType': this.utilsService.uppercaseFirstLetter(location),
              'subSchemaType': undefined,
              'btnType': 'Lineage'
            }));
          } else if (['SSIS', 'ADF'].includes(propertiesObj.tool) && !propertiesObj.main_circle) {
            // click on ssis node
            this.propertiesBtns.push(_.filter(this.propertiesBtnList, {
              'subSchemaType': 'LoopContainer',
              'schemaType': this.utilsService.uppercaseFirstLetter(location)
            }));
            this.getPropertyButtonByTool(propertiesObj);
          } else if (propertiesObj.tool !== undefined && !(['SSIS', 'ADF'].includes(propertiesObj.tool)) && !propertiesObj.main_circle) {
            // ETL that isn't ssis and not the main circle
            this.propertiesBtns.push(_.filter(this.propertiesBtnList, {
              'notSSIS': true,
              'module': propertiesObj.module
            }));
          } else if (['SSIS', 'ADF'].includes(propertiesObj.tool) && propertiesObj.main_circle) {
            // click on ssis node - main circle
            this.propertiesBtns.push(_.filter(this.propertiesBtnList, {
              'subSchemaType': 'LoopContainer',
              'mainCircle': true,
              'schemaType': this.utilsService.uppercaseFirstLetter(location)
            }));
            this.getPropertyButtonByTool(propertiesObj);
          } else if (!propertiesObj.main_circle && !propertiesObj.linkType && propertiesObj.module === 'ETL') {
            // click on node, but not the main node
            this.propertiesBtns.push(_.filter(this.propertiesBtnList, {
              'schemaType': this.utilsService.uppercaseFirstLetter(location),
              'subSchemaType': undefined,
              'clickOn': 'node',
              'module': 'ETL'
            })); // 'btnType': 'Lineage'
            for (let i = 0; i < this.propertiesBtns[0].length; i++) {
              if (this.propertiesBtns[0][i]['title'] === 'Text view') {
                this.getPropertyButtonByTool(propertiesObj);
              }
            }
          } else if (!propertiesObj.main_circle && !propertiesObj.linkType && propertiesObj.module === 'DB') {
            // click on node, but not the main node
            this.propertiesBtns.push(_.filter(this.propertiesBtnList, {
              'schemaType': this.utilsService.uppercaseFirstLetter(location),
              'subSchemaType': undefined,
              'clickOn': 'node',
              'module': 'DB'
            })); // 'btnType': 'Lineage'

            for (let i = 0; i < this.propertiesBtns[0].length; i++) {
              if (this.propertiesBtns[0][i]['title'] === 'Text view') {
                this.getPropertyButtonByTool(propertiesObj);
              }
            }
          } else if (propertiesObj.main_circle && this.referenceTableService.hasMapBtn(propertiesObj)) {
            // main circle have just map btn (if this tool have map)
            this.propertiesBtns.push(_.filter(this.propertiesBtnList, {
              'schemaType': this.utilsService.uppercaseFirstLetter(location),
              'subSchemaType': undefined,
              'btnType': 'Map',
              'clickOn': 'node',
              'module': propertiesObj.module
            }));
          }

          // add 'expand more' btn to all node on lineage page
          if (!propertiesObj.linkType) {
            if (!this.propertiesBtns[0]) {
              this.propertiesBtns[0] = [];
            }
            this.propertiesBtns[0] = this.propertiesBtns[0].concat(_.filter(this.propertiesBtnList, {
              'schemaType': this.utilsService.uppercaseFirstLetter(location),
              'btnType': 'Lineage',
              'clickOn': 'node',
              'click': 'getExpandMoreNodes'
            }));

            if (propertiesObj.in_count !== undefined) {
              const index = this.propertiesBtns[0].findIndex(obj => obj.title.indexOf(buttonsNames.Expand) > -1 &&
                obj.tooltip.indexOf('Source') > -1);
              this.propertiesBtns[0][index].title += ' (' + propertiesObj.in_count + ')';
            }
            if (propertiesObj.out_count !== undefined) {
              const index = this.propertiesBtns[0].findIndex(obj => obj.title.indexOf(buttonsNames.Expand) > -1 &&
                obj.tooltip.indexOf('Target') > -1);
              this.propertiesBtns[0][index].title += ' (' + propertiesObj.out_count + ')';
            }
          }
          break;
      }
    }
    if (location === Locations.discovery) {
      this.propertiesBtns.push(_.filter(this.propertiesBtnList, {'schemaType': this.utilsService.uppercaseFirstLetter(location)}));
    }

    if (this.propertiesBtns[0] && this.propertiesBtns[0].length > 0) {
      for (const index in this.propertiesBtns[0]) {
        this.propertiesBtns[0][index].dataObj = propertiesObj;
      }
    }

    if (this.propertiesBtns.length > 0 && this.propertiesBtns[0].length > 0) {
      for (const index in this.propertiesBtns[0]) {
        const spinnerOptions: SpinnerButtonOptions = {
          active: false,
          spinnerSize: 18,
          raised: true,
          buttonColor: 'primary',
          spinnerColor: 'grey',
          fullWidth: false,
          disabled: this.propertiesBtns[0][index].title.indexOf('(0)') > -1 ? true : false,
        };
        this.propertiesBtns[0][index].spinnerOptions = spinnerOptions;
      }
    }
  }

  // TODO: Clean 'getCorrectPropertiesBtn'
  // This method is temp before removing 'getCorrectPropertiesBtn'.
  // the different is it is working with new propertiesBtns instance.
  // It retrieve the propertiesBtns array to the caller instead of managing it in the service.
  public getPropertiesBtns(propertiesObj, location, isColumn?): any[] {
    this.initPropertiesBtnsList();
    let propertiesBtns = [];

    const getPropertyButtonByTool = () => {
      // const secondFilter = [];
      // for (let i = 0; i < propertiesBtns.length; i++) {
      //   if (propertiesBtns[i]['tool'].includes(propertiesObj.tool)) {
      //     secondFilter.push(propertiesBtns[i]);
      //   }
      // }
      // propertiesBtns = secondFilter;
      propertiesBtns = propertiesBtns.filter(btn => btn['tool']?.includes(propertiesObj.tool) || false);
    };

    // filter --> find all
    if (location) {
      switch (location) {
        case Locations.map:
          if (['ADF', 'SSIS'].includes(propertiesObj.tool) && ['ExecuteSSISPackage', 'Microsoft.ExecutePackageTask', 'Execute Package Task'].includes(propertiesObj.iconType)) {
            propertiesBtns = [...propertiesBtns, ...this.propertiesBtnList.filter(btn => btn.subSchemaType === 'ExecuteTask' && btn.schemaType === this.utilsService.uppercaseFirstLetter(location))];
            // propertiesBtns.push(_.filter(this.propertiesBtnList, {
            //   'subSchemaType': 'ExecuteTask',
            //   'schemaType': this.utilsService.uppercaseFirstLetter(location)
            // }));
          }

          if (propertiesObj.tool === 'SSIS' && (propertiesObj.iconType === 'Data Flow Task' ||
            propertiesObj.iconType === 'Execute SQL Task')) {
            propertiesBtns = [
              ...propertiesBtns,
              ...this.propertiesBtnList.filter(btn => btn.subSchemaType === undefined && btn.schemaType === this.utilsService.uppercaseFirstLetter(location) && btn.tool?.includes(propertiesObj.tool)),
            ];

            // propertiesBtns.push(_.filter(this.propertiesBtnList, {
            //   'subSchemaType': undefined,
            //   'schemaType': this.utilsService.uppercaseFirstLetter(location)
            // }));
            // getPropertyButtonByTool();
          }
          // ssis table to table btns

          // query 25
          if (['SSIS', 'ADF'].includes(propertiesObj.tool) &&
            (propertiesObj.iconType === 'ForEach' || propertiesObj.iconType === 'ExecutePipeline' ||
              propertiesObj.iconType === 'Until' || propertiesObj.iconType === 'IfCondition' ||
              // propertiesObj.iconType === 'Data Flow Task' || propertiesObj.iconType === 'Execute SQL Task' ||
              propertiesObj.iconType === 'Sequence Container' || propertiesObj.iconType === 'For Loop Container' ||
              propertiesObj.iconType === 'Foreach Loop Container' ||
              propertiesObj.iconType === 'Main Process 2021 Loop')
            || ['ExecuteSSISPackage', 'Microsoft.ExecutePackageTask', 'Execute Package Task'].includes(propertiesObj.iconType)
          ) {
            propertiesBtns = [
              ...propertiesBtns,
              ...this.propertiesBtnList.filter(btn => btn.subSchemaType === 'ControlFlow' && btn.schemaType === this.utilsService.uppercaseFirstLetter(location) && btn.tool?.includes(propertiesObj.tool))
            ];
            // propertiesBtns.push(_.filter(this.propertiesBtnList, {
            //   'subSchemaType': 'ControlFlow',
            //   'schemaType': this.utilsService.uppercaseFirstLetter(location)
            // }));
            // getPropertyButtonByTool();
          }
          if (propertiesObj.tool === 'ADF' && (propertiesObj.iconType === 'Copy' || propertiesObj.iconType === 'ExecuteDataFlow' ||
            propertiesObj.iconType === 'Lookup')) {
            propertiesBtns = [
              ...propertiesBtns,
              ...this.propertiesBtnList.filter(btn => btn.subSchemaType === undefined && btn.schemaType === this.utilsService.uppercaseFirstLetter(location) && btn.tool?.includes(propertiesObj.tool))
            ];
            // propertiesBtns.push(_.filter(this.propertiesBtnList, {
            //   'subSchemaType': undefined,
            //   'schemaType': this.utilsService.uppercaseFirstLetter(location)
            // }));
            // getPropertyButtonByTool();
          } else if (propertiesObj.isData !== '0' && propertiesObj.objectClicedkOn === 'node'
            && propertiesObj.as && this.mapIDService.arrayOfUrl['module'] === moduleType.ETL
            || this.mapIDService.arrayOfUrl['module'] === moduleType.DATABASE) {

            propertiesBtns = this.propertiesBtnList.filter(btn => {
              // In maps column, lineage object is not relevant
              if (isColumn && btn.title === buttonsNames.LineageObject) {
                return false;
              }

              if (btn.module === 'ETL' && btn.tableAs === propertiesObj.as && btn.schemaType === this.utilsService.uppercaseFirstLetter(location)) {
                if (propertiesObj.tool === 'SSAS' && btn.title === buttonsNames.LineageObject) {
                  return btn.btnType === this.utilsService.uppercaseFirstLetter(Locations.lineage) && ['Dimension', 'MeasureGroup'].includes(propertiesObj.objType);
                } else {
                  return !(this.mapIDService.arrayOfUrl['module'] === moduleType.DATABASE && propertiesObj.isData == '0') && !isColumn;
                }
              } else {
                return false;
              }
            });
            // propertiesBtns.push(a);
          } else if (propertiesObj.objectClicedkOn === 'link') {
            // dont' need to be btns
          }

          propertiesBtns.forEach(a => a.icon = a.icon || 'assets/inner-system.svg');
          break;
        case Locations.lineage:
          let filterPartA;

          // user click on the link between nodes in the lineage
          if (propertiesObj.linkType && this.referenceTableService.openLinkBtn(propertiesObj.source, propertiesObj.target)) {
            propertiesBtns.push(_.filter(this.propertiesBtnList, {'clickOn': 'link'}));
          } else if (propertiesObj.module === 'DATABASE' && !['FILE', 'HIVE', 'LOGICAL'].includes(propertiesObj.tool) && !propertiesObj.main_circle && !['table', 'package procedure'].includes(propertiesObj.db_type.toLowerCase())) {
            filterPartA = this.propertiesBtnList.filter(a =>
              a.schemaType === this.utilsService.uppercaseFirstLetter(location) &&
              a.subSchemaType === undefined &&
              a.clickOn === 'node'
            );
            propertiesBtns = [...propertiesBtns, ...filterPartA.filter(btn => btn.module !== 'REPORT')];
          } else if (propertiesObj.module === 'DATABASE' && propertiesObj.tool !== 'FILE' && propertiesObj.main_circle && propertiesObj.db_type.toLowerCase() !== 'table') { // click on node - main circle - but DB node type view or sp have map btn
            filterPartA = this.propertiesBtnList.filter(a =>
              a.schemaType === this.utilsService.uppercaseFirstLetter(location) &&
              a.subSchemaType === undefined &&
              a.clickOn === 'node' &&
              a.tableAs === ''
            );
            propertiesBtns = [...propertiesBtns, ...filterPartA.filter(btn => btn.module !== 'REPORT')];
          } else if (propertiesObj.module === 'DATABASE' && !propertiesObj.main_circle) {
            // click on node - but DB node (DB don't have map at all)
            propertiesBtns = [...propertiesBtns, ...this.propertiesBtnList.filter(a =>
              a.schemaType === this.utilsService.uppercaseFirstLetter(location) &&
              a.subSchemaType === undefined &&
              a.btnType === 'Lineage'
            )];
          } else if (['SSIS', 'ADF'].includes(propertiesObj.tool) && !propertiesObj.main_circle) {
            // click on ssis node
            propertiesBtns = [...propertiesBtns, ...this.propertiesBtnList.filter(btn => btn.subSchemaType === 'LoopContainer' && btn.schemaType === this.utilsService.uppercaseFirstLetter(location) && btn.tool?.includes(propertiesObj.tool))];
            // getPropertyButtonByTool();
          } else if (!!propertiesObj.tool && !(['SSIS', 'ADF'].includes(propertiesObj.tool)) && !propertiesObj.main_circle) {
            // ETL that isn't ssis and not the main circle
            propertiesBtns = [...propertiesBtns, ...this.propertiesBtnList.filter(a => a.notSSIS && a.module === propertiesObj.module)];
          } else if (['SSIS', 'ADF'].includes(propertiesObj.tool) && propertiesObj.main_circle) {
            // click on ssis node - main circle
            propertiesBtns = [...propertiesBtns, ...this.propertiesBtnList.filter(btn => btn.subSchemaType === 'LoopContainer' && btn.mainCircle && btn.schemaType === this.utilsService.uppercaseFirstLetter(location) && btn.tool?.includes(propertiesObj.tool))];
            // getPropertyButtonByTool();
          } else if (!propertiesObj.main_circle && !propertiesObj.linkType && propertiesObj.module === 'ETL') {
            // click on node, but not the main node
            propertiesBtns = [...propertiesBtns, ...this.propertiesBtnList.filter(a => a.schemaType === this.utilsService.uppercaseFirstLetter(location) && a.subSchemaType === undefined && a.clickOn === 'node' && a.module === 'ETL')];

            // for (let i = 0; i < propertiesBtns.length; i++) {
            //   if (propertiesBtns[i]['title'] === 'Text view') {
            //     getPropertyButtonByTool();
            //   }
            // }
          } else if (!propertiesObj.main_circle && !propertiesObj.linkType && propertiesObj.module === 'DB') {
            // click on node, but not the main node
            propertiesBtns = [...propertiesBtns, ...this.propertiesBtnList.filter(a => a.schemaType === this.utilsService.uppercaseFirstLetter(location) && a.subSchemaType === undefined && a.clickOn === 'node' && a.module === 'DB')];

            // for (let i = 0; i < propertiesBtns.length; i++) {
            //   if (propertiesBtns[i]['title'] === 'Text view') {
            //     getPropertyButtonByTool();
            //   }
            // }
          } else if (propertiesObj.main_circle && this.referenceTableService.hasMapBtn(propertiesObj)) {
            // main circle have just map btn (if this tool have map)
            propertiesBtns = [...propertiesBtns, ...this.propertiesBtnList.filter(a => a.schemaType === this.utilsService.uppercaseFirstLetter(location) && a.subSchemaType === undefined && a.btnType === 'Map' && a.clickOn === 'node' && a.module === propertiesObj.module)];
          }

          // add 'expand more' btn to all node on lineage page
          if (!propertiesObj.linkType) {
            // if (!propertiesBtns) {
            //   propertiesBtns = [];
            // }

            // propertiesBtns = propertiesBtns.concat(_.filter(this.propertiesBtnList, {
            //   'schemaType': this.utilsService.uppercaseFirstLetter(location),
            //   'btnType': 'Lineage',
            //   'clickOn': 'node',
            //   'click': 'getExpandMoreNodes'
            // }));

            propertiesBtns = [
              ...propertiesBtns,
              ...this.propertiesBtnList.filter(btn => btn.schemaType === this.utilsService.uppercaseFirstLetter(location) && btn.btnType === 'LineageExpand' && btn.clickOn === 'node' && btn.click === 'getExpandMoreNodes')
            ];

            if (!!propertiesObj.in_count) {
              const index = propertiesBtns.findIndex(obj => obj.title.indexOf(buttonsNames.Expand) > -1 && obj.tooltip.indexOf('Source') > -1);
              propertiesBtns[index].title += ` (${propertiesObj.in_count})`;
            }
            if (!!propertiesObj.out_count) {
              const index = propertiesBtns.findIndex(obj => obj.title.indexOf(buttonsNames.Expand) > -1 && obj.tooltip.indexOf('Target') > -1);
              propertiesBtns[index].title += `' (${propertiesObj.out_count})`;
            }
          }
          break;
      }
    }

    propertiesBtns = propertiesBtns.map(a => {
      return {
        ...a,
        dataObj: propertiesObj,
        spinnerOptions: {
          active: false,
          spinnerSize: 18,
          raised: true,
          buttonColor: 'primary',
          spinnerColor: 'grey',
          fullWidth: false,
          disabled: a.title.indexOf('(0)') > -1 ? true : false,
        },
      };
    });

    // if (propertiesBtns && propertiesBtns.length > 0) {
    // 	for (const index in propertiesBtns) {
    // 		propertiesBtns[index].dataObj = propertiesObj;
    // 	}
    // }

    // if (propertiesBtns.length > 0) {
    //   for (const index in propertiesBtns) {
    //     const spinnerOptions: SpinnerButtonOptions = {
    //       active: false,
    //       spinnerSize: 18,
    //       raised: true,
    //       buttonColor: 'primary',
    //       spinnerColor: 'grey',
    //       fullWidth: false,
    //       disabled: propertiesBtns[index].title.indexOf('(0)') > -1 ? true : false,
    //     };
    //     propertiesBtns[index].spinnerOptions = spinnerOptions;
    //   }
    // }

    return propertiesBtns;
  }

  public goTo(event, obj, subLocation, location, connectionsIds): void {
    if (this.disableButtons) {
      return;
    }
    obj.value.spinnerOptions.active = true;
    this.pageConfigService.generalHosterMode = 'map-id';
    this.disableButtons = true;
    // if user click to drill right or left btns OR click on link between nodes in lineage - don't close the right menu
    if (obj.value.click !== 'goToDrillRightLeft' && obj.value.click !== 'goToLinkETLandDB') {
      this.sideBarService.close('connections');
    }
    if (event.target.innerText === 'Text view') {
      this.setTextView();
    } else if (event.target.innerText === buttonsNames.MapView) {
      this.mapIDService.selectedTabIndex = 0;
    }
    this[obj.value.click](obj, subLocation, location, connectionsIds);
  }

  // 25
  public goToSSISSecondLevel(obj, location, connectionsIds) {
    this.disableButtons = false;
    const module = this.moduleUtilsService.getToolType(obj.value.dataObj.tool);
    let tableToTableMap: TableToTableMap;

    tableToTableMap = {
      module: module,
      tool: obj.value.dataObj.tool,
      pathFolder: obj.value.dataObj.parent_folder,
      name: obj.value.dataObj.name,
      cfId: obj.value.dataObj.cf_id,
      connIDs: connectionsIds,
      queryNum: this.mapsService.whiceQuerynumber(obj.value.dataObj.tool, module, {is_control_flow_step: obj.value.dataObj.is_control_flow_step})// 25
    };

    this.mapIDService.initVals();
    this.mapsService.setParamsForMap({
      module: module,
      tool: obj.value.dataObj.tool,
      tableToTableMap: tableToTableMap
    });
    this.sideBarService.close('properties');
    this.sideBarService.close('connections');
  }

  // 23
  public goToSSISFirstLevel(obj, location, connectionsIds) {
    this.disableButtons = false;
    const module = this.moduleUtilsService.getToolType(obj.value.dataObj.tool);
    let tableToTableMap: TableToTableMap;

    tableToTableMap = {
      module: module,
      tool: obj.value.dataObj.tool,
      pathFolder: obj.value.dataObj.path_folder,
      name: obj.value.dataObj.name,
      connIDs: connectionsIds,
      queryNum: this.mapsService.whiceQuerynumber(obj.value.dataObj.tool, module)// 23
    };

    this.mapIDService.initVals();
    this.mapsService.setParamsForMap({
      module,
      tool: obj.value.dataObj.tool,
      tableToTableMap: tableToTableMap
    });
    this.sideBarService.close('properties');
    this.sideBarService.close('connections');
  }


  // 312 OR 309
  public goToColToCol(obj, location, connectionsIds) {
    this.disableButtons = false;
    let module;
    if (location === Locations.discovery) {
      module = this.moduleUtilsService.getToolType(obj.value.dataObj.selected_module);
    } else {
      module = (obj.value.dataObj.module.toUpperCase() !== 'ETL' && obj.value.dataObj.module.toUpperCase() !== 'DATABASE' && obj.value.dataObj.module.toUpperCase() !== 'REPORT') ? this.moduleUtilsService.getToolType(obj.value.dataObj.tool) : obj.value.dataObj.module;
    }

    let colToColMapETL: ColToColMapETL;
    // DATABASE -> (instead of calling to GSP, we calling 312)
    let colToColMapDATABASE: ColToColMapETL;
    let colToColMapREPORT: ColToColMapREPORT;

    switch (module) {
      case 'ETL':
        colToColMapETL = {
          module,
          tool: obj.value.dataObj.tool,
          queryNum: this.mapsService.whiceQuerynumber(obj.value.dataObj.tool, module, {is_loop_container_step: obj.value.dataObj.is_loop_container_step}), // 312
          containerObjPath: obj.value.dataObj.path_folder || obj.value.dataObj.parent_folder || obj.value.dataObj.Package_Path || obj.value.dataObj.Folder,
          controlFlowPath: obj.value.dataObj.key || obj.value.dataObj.name || obj.value.dataObj.DFD_Path || obj.value.dataObj.Map,
          name: obj.value.dataObj.name || obj.value.dataObj.Package_Name || obj.value.dataObj.Map || obj.value.dataObj.Job_Name,
          connIDs: connectionsIds,
          connName: obj.value.dataObj.connection_logic_name || obj.value.dataObj.Connection_Logic_Name,
          objectType: obj.value.dataObj.db_type,
        };
        break;
      case 'REPORT':
        colToColMapREPORT = {
          module,
          tool: obj.value.dataObj.tool,
          name: obj.value.dataObj.name || obj.value.dataObj.Report_Name || obj.value.dataObj.Table_Name,
          pathFolder: obj.value.dataObj.path_folder || obj.value.dataObj.URL || obj.value.dataObj.File_Path,
          // connIDs: connectionsIds,
          queryNum: this.mapsService.whiceQuerynumber(obj.value.dataObj.tool, module, {is_loop_container_step: obj.value.dataObj.is_loop_container_step}), // 309
          objectType: obj.value.dataObj.db_type,
          connName: obj.value.dataObj.connection_logic_name || obj.value.dataObj.Connection_Logic_Name,
        };
        break;
      case 'FILES':
        colToColMapETL = {
          module: 'ETL',
          tool: obj.value.dataObj.tool,
          queryNum: this.mapsService.whiceQuerynumber(obj.value.dataObj.tool, module, {is_loop_container_step: obj.value.dataObj.is_loop_container_step}), // 312
          containerObjPath: obj.value.dataObj.path_folder || obj.value.dataObj.parent_folder || obj.value.dataObj.Package_Path || obj.value.dataObj.Folder || obj.value.dataObj.File_Path,
          controlFlowPath: obj.value.dataObj.key || obj.value.dataObj.name || obj.value.dataObj.DFD_Path || obj.value.dataObj.Map || obj.value.dataObj.File_Name,
          name: obj.value.dataObj.name || obj.value.dataObj.Package_Name || obj.value.dataObj.Map || obj.value.dataObj.Job_Name || obj.value.dataObj.File_Name,
          connIDs: connectionsIds,
          connName: obj.value.dataObj.connection_logic_name || obj.value.dataObj.Connection_Logic_Name,
          objectType: obj.value.dataObj.db_type,
        };
        break;
      default:
        if (this.moduleUtilsService.getModuleLongName(module) === 'DATABASE') {
          let containerObjPath;
          if (obj.value.dataObj.serverName || obj.value.dataObj.Server_Name) {
            containerObjPath = (obj.value.dataObj.serverName || obj.value.dataObj.Server_Name) + '.' + (obj.value.dataObj.db_name || obj.value.dataObj.Database_Name) + '.' + (obj.value.dataObj.schema || obj.value.dataObj.Schema_Name || obj.value.dataObj.Owner);
          } else {
            containerObjPath = (obj.value.dataObj.db_name || obj.value.dataObj.Database_Name) + '.' + (obj.value.dataObj.schema || obj.value.dataObj.Schema_Name || obj.value.dataObj.Owner);
          }

          let controlFlowPath = obj.value.dataObj.name || obj.value.dataObj.Object_Name || obj.value.dataObj.Table_Name;
          if (this.moduleUtilsService.getToolType(obj.value.dataObj.tool.toUpperCase()) === moduleType.ANALYSIS || obj.value.dataObj.subModule === moduleType.ANALYSIS) {
            controlFlowPath = '-1';
          }

          colToColMapDATABASE = {
            module: this.moduleUtilsService.getModuleLongName(module),
            tool: obj.value.dataObj.tool,
            queryNum: this.mapsService.whiceQuerynumber(obj.value.dataObj.tool, module, {is_loop_container_step: obj.value.dataObj.is_loop_container_step}), // 312
            containerObjPath: containerObjPath,
            oldContainerObjPath: `${(obj.value.dataObj.db_name || obj.value.dataObj.Database_Name)}.${(obj.value.dataObj.schema || obj.value.dataObj.Schema_Name || obj.value.dataObj.Owner)}`,
            controlFlowPath: controlFlowPath,
            name: obj.value.dataObj.name || obj.value.dataObj.Object_Name || obj.value.dataObj.Table_Name,
            connIDs: connectionsIds,
            connName: obj.value.dataObj.connection_logic_name || obj.value.dataObj.Connection_Logic_Name,
            objectType: obj.value.dataObj.db_type || obj.value.dataObj.Object_Type,
          };
        }
    }

    this.mapIDService.initVals();
    this.mapsService.setParamsForMap({
      module: this.moduleUtilsService.getModuleLongName(module),
      tool: obj.value.dataObj.tool,
      colToColMapETL: colToColMapETL,
      colToColMapREPORT: colToColMapREPORT,
      colToColMapDATABASE: colToColMapDATABASE,
    });

    this.sideBarService.close('properties');
    this.sideBarService.close('connections');
  }

  public goToLineage(obj, location, connectionsIds) {
    this.disableButtons = false;
    obj.from_module_list = true;

    this.splashScreenService.setLoaderOn();

    if (obj.value.schemaType !== Locations.lineage) {
      // search the rid
      this.getRID(obj)
        .subscribe(response => {
          if (response.length > 0) {
            obj.value.dataObj.rID = response[0]['@rid'];
            obj.value.dataObj.ObjectGUID = response[0].ObjectGUID;
          }
          this.lineageService.buildObjForLineageQuery(obj);
          // we need this if because when we came from map to lineage,
          // the request to the server happend twice (once from the previous row and one from this comment row)
          if (location.toLowerCase() === Locations.lineage) {
            this.lineageIDService.buildParamsToGetLineageSchema();
          }
        });
    } else {
      this.lineageService.buildObjForLineageQuery(obj);
      // we need this if becuase when we came from map to lineage, the request to the server happend twice (once from the previous row and one from this comment row)
      if (this.utilsService.uppercaseFirstLetter(location) === 'Lineage') {
        this.lineageIDService.buildParamsToGetLineageSchema();
      }
    }

    this.sideBarService.close('properties');
    this.sideBarService.close('connections');
  }

  // 27
  public goToThirdLevel(obj, location, connectionsIds) {
    const _module = this.moduleUtilsService.getToolType(this.moduleUtilsService.getToolName(obj.value.dataObj.tool));
    let colToColMapETLDrill: ColToColMapETLDrill;
    const _step = {
      'is_execute_task': true
    };

    colToColMapETLDrill = {
      module: _module,
      tool: obj.value.dataObj.tool,
      queryNum: this.mapsService.whiceQuerynumber(obj.value.dataObj.tool, _module, _step), // 27
      packageName: obj.value.dataObj.nameValue,
      connIDs: connectionsIds,
      connName: obj.value.dataObj.connection_logic_name
    };

    const fullObj: MapObjs = {
      module: _module,
      tool: obj.value.dataObj.tool,
      colToColMapETLDrill: colToColMapETLDrill
    };

    this.drillBetweenMapsService.getMapDrillData(fullObj.colToColMapETLDrill)
      .subscribe(response => {

        // obj.value.spinnerOptions?.active = false;
        this.disableButtons = false;

        // map not exist
        if (response[0] === '[]' || response[0] === 'null') {
          this.toastrService.error('No results');
          return;
        } else { // show options of map schems
          response[0] = JSON.parse(response[0]);
          this.showModalResults = true;
          // show results on modal
          const dialogHeader = `List of Packages`;
          this.openModal(response[0], fullObj.colToColMapETLDrill, dialogHeader);
          this.sideBarService.close('properties');
        }
      }, error => {
      });
  }

  // 318 + 319
  public goToDrillRightLeft(obj, location, connectionsIds) {
    const _module = this.moduleUtilsService.getToolType(this.moduleUtilsService.getToolName(obj.value.dataObj.tool));
    let colToColMapETLDrillLeftRight: ColToColMapETLDrillLeftRight;
    const _step = {};
    (obj.value.title === buttonsNames.Back) ? _step['is_drill_left_step'] = true : _step['is_drill_right_step'] = true;

    colToColMapETLDrillLeftRight = {
      module: _module,
      tool: obj.value.dataObj.tool,
      queryNum: this.mapsService.whiceQuerynumber(obj.value.dataObj.tool, _module, _step), // 318
      containerObjPath: obj.value.dataObj.containerObjectPath,
      name: obj.value.dataObj.componentName || obj.value.dataObj.containerObjectName,
      containerObjName: obj.value.dataObj.controlFlowPath,
      dbName: obj.value.dataObj.db_name || obj.value.dataObj.path_folder || -1,
      schema: obj.value.dataObj.schema || -1,
      tableName: obj.value.dataObj.tableName,
      connIDs: connectionsIds,
      connName: obj.value.dataObj.connection_logic_name
    };

    const fullObj: MapObjs = {
      module: _module,
      tool: obj.value.dataObj.tool,
      colToColMapETLDrillLeftRight: colToColMapETLDrillLeftRight
    };

    this.drillBetweenMapsService.getMapDrillData(fullObj.colToColMapETLDrillLeftRight)
      .subscribe(response => {

        // obj.value.spinnerOptions?.active = false;
        this.disableButtons = false;

        // map not exist
        if (response[0] === '[]' || response[0] === 'null') {
          if (obj.value.title === buttonsNames.Back) {
            this.toastrService.error('The issued table is not a target in other ETL processes');
          } else if (obj.value.title === buttonsNames.Fwd) {
            this.toastrService.error('The issued table is not a source in other ETL processes');
          }
          return;
        } else { // show options of map schems
          response[0] = JSON.parse(response[0]);
          this.showModalResults = true;
          // show results on modal
          const dialogHeader = `Object in other maps as ${colToColMapETLDrillLeftRight.queryNum == 319 ? 'target' : 'source'}`;
          this.openModal(response[0], fullObj.colToColMapETLDrillLeftRight, dialogHeader);
          this.sideBarService.close('properties');
        }
      }, error => {
      });
  }

  // 320
  public goToLinkETLandDB(link_item, connectionId) {
    let source_to_server = link_item.source;
    let target_to_server = link_item.target;

    if (link_item.target.type === 'ETL') {
      source_to_server = link_item.target;
      target_to_server = link_item.source;
    }

    const module = this.moduleUtilsService.getToolType(link_item.target.tool);
    let colToColMapLinkETLandDB: ColToColMapLinkETLandDB;

    colToColMapLinkETLandDB = {
      module,
      tool: link_item.source.tool,
      queryNum: this.mapsService.whiceQuerynumber('both', module), // 320
      containerObjPath: link_item.target.type === 'ETL' ? link_item.target.properties.Folder : link_item.source.properties.Folder, // ETL property
      name: target_to_server.name,
      containerObjName: target_to_server.name, // ETL property
      dbName: target_to_server.properties.DB || -1, // DB property
      schema: target_to_server.properties.Schema || -1, // DB property
      tableName: target_to_server.name, // DB property
      connIDs: this.lineageService.connectionId,
      connName: link_item.source.properties.ConnectionName
    };

    const fullObj: MapObjs = {
      module,
      tool: link_item.tool,
      colToColMapLinkETLandDB: colToColMapLinkETLandDB
    };

    this.splashScreenService.setLoaderOn();
    this.storeLineage.dispatch(new LineageActions.SetLoadingAndDisableDiagram({loadingAndDisableDiagram: true}));

    // go to server, if exist results - show them in modal
    // the user should choose
    // and then direct to the new url
    let vm = this;
    this.drillBetweenMapsService.getMapDrillData(fullObj.colToColMapLinkETLandDB).subscribe(response => {
      this.splashScreenService.isLoaderOn = false;
      this.storeLineage.dispatch(new LineageActions.SetLoadingAndDisableDiagram({loadingAndDisableDiagram: false}));
      // map not exist
      if (response[0] === '[]' || response[0] === 'null') {
        vm.toastrService.error('Map not found!');
        return;
      } else { // show options of map schems
        response[0] = JSON.parse(response[0]);
      }
      const dialogHeader = `Object in other maps as ${colToColMapLinkETLandDB.queryNum == 319 ? 'target' : 'source'}`;

      // show results on modal
      vm.openModal(response[0], fullObj.colToColMapLinkETLandDB, dialogHeader);
    });
  }

  private setTextView() {
    this.mapIDService.openTextView = true;
    setTimeout(() => {
      this.mapIDService.openTextView = false;
    }, 2000);
  }

  private getRID(obj) {
    const lineageIdObj = {
      outputFields: '*', // "@rid",
      keyValues:
        [
          {
            txtSearch: obj.value.dataObj.module,
            operator: 4, // 1- startWith	 2- endWith		3- contains		4-equal
            txtfield: 'ObjectName',
            connector: ''
          },
          {
            txtSearch: obj.value.dataObj.path_folder || '',
            operator: 4, // 1- startWith	 2- endWith		3- contains		4-equal
            txtfield: 'DatabaseName',
            connector: 'and'
          },
          {
            txtSearch: obj.value.dataObj.schema ? obj.value.dataObj.schema.toLowerCase() : obj.value.dataObj.schema || '',
            operator: 4, // 1- startWith	 2- endWith		3- contains		4-equal
            txtfield: 'SchemaName',
            connector: 'and'
          },
          {
            txtSearch: '0',
            operator: 4, // 1- startWith	 2- endWith		3- contains		4-equal
            txtfield: 'IsFaked',
            connector: 'and'
          },

          // {
          // 	txtSearch: this.connectionsIds,
          // 	operator: 4, // 1- startWith	 2- endWith		3- contains		4-equal
          // 	txtfield: "connIDs",
          // 	connector: "and"
          // }
        ],
    };
    if (obj.value.dataObj.tool.toLowerCase() === 'sqls') {
      const connLogicNameObj = {
        txtSearch: obj.value.dataObj.connection_logic_name,
        operator: 4, // 1- startWith	 2- endWith		3- contains		4-equal
        txtfield: 'ConnLogicName',
        connector: 'and'
      };
      lineageIdObj.keyValues.push(connLogicNameObj);
    }

    return this.httpUtilsService.postData(this.API_ENDPOINT, lineageIdObj, true)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  public  getUsageData(node): Observable<any> {
	  return this.httpUtilsService.post('lineageGener/runUsageQuery', node, true);
  }

  private openModal(dataObj, sendedObj, header): void {
    const obj = {
      width: '900px',
      data: {
        title: sendedObj,
        results: dataObj,
        dialogHeader: header
      }
    };
    this.drillBetweenMapsService.dialogRef = this.dialog.open(DrillBetweenMapsComponent, obj);
  }
}
