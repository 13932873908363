import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import { ModuleUtilsService } from '@core/services/module-utils.service';
import { Locations } from '@shared/enums/locations.enum';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PageConfigService } from '@core/services/page-config.service';
import { SetDefaultSearch, SetSearchValue } from '@store/search/actions';
import { GetAbgItem, SetFiltered, SetSearchFilters } from '@main/adc/adc-store/adc/actions';
import {
  SetAdcDashboardFiltered,
  SetAdcDashboardSearchFilters,
  SetViewType
} from '@main/adc/adc-store/adc-dashboard/actions';
import { setInnerSearch } from '@store/e2e/actions';
import { SearchSource } from '@store/lineage';
import * as DiscoveryActions from '@store/discovery/actions';
import { Subject } from 'rxjs';
import { UtilsService } from '@core/services/utils.service';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { SearchType } from '@shared/enums/searchType';
import { AbgService } from '@main/adc/adc.service';
import { updateActivity } from '@store/activities/actions';
import { SideBySideService } from '@shared/components/side-by-side/side-by-side.service';
import { setLineageByTypeSearchValue } from '@store/lineageDashboard/actions';
import { moduleType } from '@shared/enums/module-type.enum';

@Component({
  selector: 'oct-timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.scss'],
})
export class TimelineItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() item: any;
  @Input() index: number;
  @Input() cp: number;
  @HostBinding('class') classes = 'm-list-timeline__item';
  public location: Locations;
  public locations: typeof Locations = Locations;
  public isDeleteIcon: boolean = true;
  private componentDestroy$ = new Subject();

  constructor(
    public moduleUtilsService: ModuleUtilsService,
    private router: Router,
    private store: Store<any>,
    private pageConfigService: PageConfigService,
    private httpUtilsService: HttpUtilsService,
    private utilsService: UtilsService,
    private adcService: AbgService,
    private sideBySideService: SideBySideService,
  ) {
  }

  ngOnInit() {
    if (this.item.to_delete) {
      this.isDeleteIcon = false;
    }
    if (this.item.module === 'Discovery' && this.item.activity === 'Show Button Data') {
      const searchArr = JSON.parse(this.item.search_value);
      this.item.search_value = searchArr[0];
      this.item.search_value_from_url = searchArr[1];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item) {
      const itemChange: SimpleChange = changes.item.currentValue;
      if (itemChange) {
        if (this.isDeleteIcon) {
          this.getColor();
        } else {
          this.changeColorToDisable();
        }
      }
    }
  }

  public startAction(item) {
    if (!this.isDeleteIcon) {
      return;
    }

    let dec_url;
    switch (item.module) {
      case 'Cross System Lineage Dashboard - Inner search bar':
      case 'Cross System Lineage Dashboard - Top search bar':
      case 'Cross System Lineage Dashboard - Search bar':
        dec_url = JSON.parse(this.pageConfigService.decodeBtoa(item.url));
        this.store.dispatch(new SetDefaultSearch(dec_url.defaultSearchValue, Locations.lineage));
        this.store.dispatch(setLineageByTypeSearchValue({type: moduleType.ETL, value: dec_url.searchETL}));
        this.store.dispatch(setLineageByTypeSearchValue({type: moduleType.DB, value: dec_url.searchDB}));
        this.store.dispatch(setLineageByTypeSearchValue({type: moduleType.REPORT, value: dec_url.searchREPORT}));
        this.router.navigate([Locations.lineage]);

        // this.store.dispatch(new SetLineageSearchValue({queryNum: 302, value: dec_url.searchETL}));
        // this.store.dispatch(new SetLineageSearchValue({queryNum: 303, value: dec_url.searchDB}));
        // this.store.dispatch(new SetLineageSearchValue({queryNum: 304, value: dec_url.searchREPORT}));
        // this.store.dispatch(new SetDefaultSearch(dec_url.defaultSearchValue, Locations.lineage));
        // const searchInfo: SearchInfo = {
        //   searchSource: this.getSearchSource(dec_url),
        //   outer_search: dec_url.defaultSearchValue,
        //   inner_search: null,
        //   query_num: null,
        //   module: null
        // };
        // this.store.dispatch(new GetLineageData(searchInfo));
        // this.router.navigate([Locations.lineage]);
        break;
      case 'Inner System Lineage':
        this.router.navigate([Locations.map, item.url]);
        break;
      case 'Cross System Lineage':
        this.router.navigate([Locations.lineageSchema, item.url]);
        break;
      case 'Discovery':
        dec_url = JSON.parse(this.pageConfigService.decodeBtoa(item.url));
        this.store.dispatch(new SetSearchValue({
          searchType: dec_url.searchType,
          searchValue: dec_url.searchValue,
          location: Locations.discovery,
        }));
        // this.store.dispatch(new SetDefaultSearch(dec_url.searchValue, Locations.discovery));
        if (dec_url.selectedObject) {
          this.store.dispatch(new DiscoveryActions.UpdateSelectedObject(dec_url.selectedObject));
        } else {
          this.store.dispatch(new DiscoveryActions.UpdateSelectedObject(null));
        }
        this.router.navigate([Locations.discovery, item.url], {queryParams: {recentActivities: true}});
        break;
      case 'End To End Lineage Dashboard - Inner search bar':
      case 'End To End Lineage Dashboard - Top search bar':
      case 'End To End Lineage Dashboard - Search bar':
        dec_url = JSON.parse(this.pageConfigService.decodeBtoa(item.url));
        this.store.dispatch(new SetDefaultSearch(dec_url.defaultSearchValue, Locations.e2eColumnDashboard));
        this.store.dispatch(setInnerSearch({searchType: 'searchETL', searchValue: dec_url.searchETL}));
        this.store.dispatch(setInnerSearch({searchType: 'searchDB', searchValue: dec_url.searchDB}));
        this.store.dispatch(setInnerSearch({searchType: 'searchREPORT', searchValue: dec_url.searchREPORT}));
        this.router.navigate(['e2e-column-dashboard']);
        break;
      case 'E2E Column Lineage':
        this.router.navigate(['e2e-column', item.url]);
        break;
      // case 'Cross System ETLs Compare':
      //     this.router.navigate(['compare/compare-etls', item.url]);
      //     break;
      // case 'Cross System Report Compare':
      //     this.router.navigate(['compare/compare-reports', item.url]);
      //     break;
      case 'Automated Data Catalog':
        dec_url = JSON.parse(this.pageConfigService.decodeBtoa(item.url));

        this.store.dispatch(new SetSearchValue({
          searchType: dec_url.searchType,
          searchValue: dec_url.searchValue,
          location: Locations.adc,
        }));
        this.store.dispatch(new SetFiltered(dec_url.filtered || false));
        this.store.dispatch(new SetSearchFilters({[this.adcService.fromPage]: dec_url.filters}));

        if (dec_url.selectedItemId) {
          this.store.dispatch(new GetAbgItem(dec_url.selectedItemId));
        }

        this.router.navigate([Locations.adc, item.url]);
        break;
      case 'Automated Data Catalog Dashboard':
        dec_url = JSON.parse(this.pageConfigService.decodeBtoa(item.url));

        this.store.dispatch(new SetSearchValue({
          searchType: SearchType.default,
          searchValue: dec_url.search_value,
          location: Locations.adcDashboard,
        }));

        // this.store.dispatch(new SetDefaultSearch(dec_url.search_value, Locations.adcDashboard));
        this.store.dispatch(new SetAdcDashboardFiltered(dec_url.filtered || false));
        this.store.dispatch(new SetAdcDashboardSearchFilters(dec_url.filters));
        this.store.dispatch(new SetViewType(dec_url.page));
        if (dec_url.page) {
          this.router.navigate([Locations.adcDashboard, dec_url.page]);
        } else {
          this.router.navigate([Locations.adcDashboard]);
        }
        break;
      case 'Live Lineage':
        const urlSegments = item.url.split('&');
        const url = urlSegments[0];
        const script = urlSegments[1];
        this.sideBySideService.saveStateScript = null;
        this.sideBySideService.scriptFromAnalysis = null;
        this.router.navigate([Locations.liveVisualizer, url]);
        break;
    }
  }

  public deleteItem(e) {
    e.stopPropagation(); // don't close the modal while click to delete
    this.isDeleteIcon = false;
    this.store.dispatch(updateActivity({payload: {id: this.item.usage_id, value: true}}));
    this.changeColorToDisable();
  }

  public undoItem(e) {
    e.stopPropagation(); // don't close the modal while click to undo
    this.isDeleteIcon = true;
    this.store.dispatch(updateActivity({payload: {id: this.item.usage_id, value: false}}));
    this.getColor();
  }

  public showModuleInTooltip(item) {
    switch (item.module) {
      case 'Cross System Lineage Dashboard - Inner search bar':
      case 'Cross System Lineage Dashboard - Top search bar':
      case 'Cross System Lineage Dashboard - Search bar':
        return 'Cross System Lineage Dashboard';
      case 'End To End Lineage Dashboard - Inner search bar':
      case 'End To End Lineage Dashboard - Top search bar':
      case 'End To End Lineage Dashboard - Search bar':
        return 'End To End Lineage Dashboard';
      default:
        return item.module;
    }
  }

  ngOnDestroy(): void {
    this.componentDestroy$.next();
    this.componentDestroy$.unsubscribe();
  }

  public onFilterPopup() {
    if (this.item.filtered) {
      return JSON.parse(this.pageConfigService.decodeBtoa(this.utilsService.fixUrlEncode(this.item.url))).filters;
    }
  }

  public openCloseFilterPopup(e, p) {
    e.stopPropagation();
    p.isOpen() ? p.close() : p.open();
  }

  private getColor() {
    switch (this.item.tool_type) {
      case 'ETL':
        this.item.color = '#36C5F0';
        break;
      case 'REPORT':
        this.item.color = '#2eb67d';
        break;
      case 'DB':
      case 'DATABASE':
      case 'FILES':
        this.item.color = '#E01E5A';
        break;
      case 'ANALYSIS':
        this.item.color = '#ECB22E';
        break;
      default:
        this.item.color = 'rgb(237, 94, 72)'; // octopai color
        break;
    }
  }

  private changeColorToDisable() {
    switch (this.item.tool_type) {
      case 'ETL':
        this.item.color = 'rgba(115,194,251, 0.3)';
        break;
      case 'REPORT':
        this.item.color = 'rgba(59, 177, 67, 0.3)';
        break;
      case 'DB':
      case 'DATABASE':
      case 'FILES':
        this.item.color = 'rgba(224, 30, 90, 0.3)';
        break;
      case 'ANALYSIS':
        this.item.color = 'rgba(249,149,29, 0.3)';
        break;
      default:
        this.item.color = 'rgba(237, 94, 72, 0.3)'; // octopai color
        break;
    }
  }

  private getSearchSource(obj) {
    if (obj.defaultSearchValue && (obj.searchETL || obj.searchDB || obj.searchREPORT)) {
      return SearchSource.outerInner;
    } else if (!obj.defaultSearchValue && (obj.searchETL || obj.searchDB || obj.searchREPORT)) {
      return SearchSource.inner;
    } else if (obj.defaultSearchValue && (!obj.searchETL && !obj.searchDB && !obj.searchREPORT)) {
      return SearchSource.outer;
    }
  }
}
