export interface ILineageMapItem {
  shortcut?: string;
  hint?: string;
  map?: boolean;
}

export interface ILineageMapper {
  [key: string]: ILineageMapItem;
}

const LINEAGE_MAP: ILineageMapper = {
  'PACKAGE PROCEDURE': {
    shortcut: 'PP',
    hint: 'Package Procedure',
    map: false,
  },
  'EXTERNAL STORED PROCEDURE': {
    shortcut: 'SP',
    hint: 'Stored Procedure',
    map: true
  },
  'MACRO': {
    shortcut: 'M',
    hint: 'Macro',
    map: true
  },
  'SCALAR UDF': {
    shortcut: 'FN',
    hint: 'Function',
  },
  'STOREDPROCEDURE': {
    shortcut: 'SP',
    hint: 'Stored Procedure',
  },
  'STORED PROCEDURE': {
    shortcut: 'SP',
    hint: 'Stored Procedure',
    map: true
  },
  'PIPE': {
    shortcut: 'PI',
    hint: 'Pipe',
    map: true
  },
  'EXTERNAL TABLE': {
    shortcut: 'ET',
    hint: 'External Table',
    map: true
  },
  'STAGE': {
    shortcut: 'STG',
    hint: 'Stage Mapping',

    map: true
  },
  'TABLE': {
    shortcut: 'T',
    hint: 'Table',
  },
  'TABLE FUNCTION': {
    shortcut: 'FN',
    hint: 'Function',
  },
  'VIEW': {
    shortcut: 'V',
    hint: 'View',
    map: true
  },
  'SQL_STORED_PROCEDURE': {
    shortcut: 'SP',
    hint: 'Stored Procedure',
    map: true
  },
  'SQL_SCALAR_FUNCTION': {
    shortcut: 'FN',
    hint: 'Function',
  },
  'SQL_TABLE_VALUED_FUNCTION': {
    shortcut: 'FN',
    hint: 'Function',
  },
  'SQL_INLINE_TABLE_VALUED_FUNCTION': {
    shortcut: 'FN',
    hint: 'Function',
  },
  'PACKAGE BODY': {
    shortcut: 'PKG',
    hint: 'Package',
  },
  'FUNCTION': {
    shortcut: 'FN',
    hint: 'Function',
  },
  'MATERIALIZED VIEW': {
    shortcut: 'MV',
    hint: ' Materialized View',
    map: true
  },
  'TRIGGER': {
    shortcut: 'TR',
    hint: 'Trigger',
  },
  'PROCEDURE': {
    shortcut: 'P',
    hint: 'Procedure',
    map: true
  },
  'SYNONYM': {
    shortcut: 'SY',
    hint: 'Synonym',
    map: true
  },
  'PROJECTION': {
    shortcut: 'PR',
    hint: 'Projection',
    map: true
  },
  'FILE': {
    shortcut: 'FI',
    hint: 'File',
  },
  'MEASUREGROUP': {
    shortcut: 'MG',
    hint: 'Measure Group',
    map: true
  },
  'DIMENSION': {
    shortcut: 'DIM',
    hint: 'Dimension',
    map: true
  },
  'TABULAR TABLE': {
    shortcut: 'TT',
    hint: 'Tabular Table',
    // map: true
  },
  'HIVE OBJECT': {
    shortcut: 'HO',
    hint: 'Hive Object',
  },
  'LOGICAL OBJECT': {
    shortcut: 'HO',
    hint: 'Hive Object',
  },
  'INTELLIGENTCUBE': {
    shortcut: 'IC',
    hint: 'Intelligent Cube',
    map: true
  },
  'CUBE': {
    shortcut: 'CU',
    hint: 'Cube',
    map: true
  },
  'DATASET TABLE': {
    shortcut: 'DS',
    hint: 'Dataset Table',
    map: true
  },
  'QLIKTABLE': {
    shortcut: 'QT',
    hint: 'Qlik Table',
  },
  '': {
    shortcut: 'UNK',
    hint: 'Unknown / Missing Details',
  }
};

export class LineageMappings {

  public getShortType(key: string) {
    if (LINEAGE_MAP[key]) {
      return this.getIndicator(key).shortcut;
    } else {
      return this.getIndicator('').shortcut;
    }
  }

  public getHint(key) {
    if (LINEAGE_MAP[key]) {
      return this.getIndicator(key).hint;
    } else {
      return '';
    }
  }

  public getMap(key): boolean {
    if (LINEAGE_MAP[key]) {
      return this.getIndicator(key).map;
    } else {
      return false;
    }
  }

  public displayTypeIndicator(obj: any) {
    if (obj && LINEAGE_MAP[obj.properties.ObjectType.toUpperCase()]) {
      return true;
    }
    return false;
  }

  private getIndicator(key: string) {
    switch (key) {
      case 'EXTERNAL STORED PROCEDURE':
      case 'MACRO':
      case 'SCALAR UDF':
      case 'STOREDPROCEDURE':
      case 'STORED PROCEDURE':
      case 'PIPE':
      case 'EXTERNAL TABLE':
      case 'STAGE':
      case 'TABLE':
      case 'TABLE FUNCTION':
      case 'VIEW':
      case 'SQL_STORED_PROCEDURE':
      case 'SQL_SCALAR_FUNCTION':
      case 'SQL_TABLE_VALUED_FUNCTION':
      case 'SQL_INLINE_TABLE_VALUED_FUNCTION':
      case 'PACKAGE PROCEDURE':
      case 'PACKAGE BODY':
      case 'FUNCTION':
      case 'MATERIALIZED VIEW':
      case 'TRIGGER':
      case 'PROCEDURE':
      case 'SYNONYM':
      case 'PROJECTION':
      case 'FILE':
      case 'MEASUREGROUP':
      case 'DIMENSION':
      case 'TABULAR TABLE':
      case 'HIVE OBJECT':
      case 'LOGICAL OBJECT':
      case 'INTELLIGENTCUBE':
      case 'CUBE':
      case 'DATASET TABLE':
      case 'QLIKTABLE':
        return LINEAGE_MAP[key];
      default:
        return LINEAGE_MAP[''];
    }
  }
}
